import { classNames } from "pages/Mobile/Positions/[token]/Components/DurationSelector";
import { primaryButtonClass, tokens } from "pages/Mobile/Earn";
import React, { useEffect } from "react";
import Link from "components/Link";
import { Web3BombappContext } from "context/Web3Bombapp";
import useRefresh from "../../hooks/useRefresh";
import { BombApiContext } from "context/BombApiContext";
import ClaimBonusModal from "components/ClaimBonus/ClaimBonusModal";

const BonusSection = ({ userBonus, userInfo }: { userBonus: any, userInfo: any }) => {
  const [showModal, setShowModal] = React.useState(false);
  const decimals = 18;

  // Check if user already has claimed the bonus
  if (userBonus.bonusToken !== "0x0000000000000000000000000000000000000000") {
    const bonusUnlocked = Number(userBonus.bonusAmount) * 10 - Number(userBonus.stakingDeposits) <= 0;
    // They haven't deposited enough yet (show remaining)
    if (!bonusUnlocked) {
      return (
        <div className="ml-6">
          <p className="text-2xl">Your bonus is waiting</p>
          <p>
            Stake {((Number(userBonus.bonusAmount) * 10 - Number(userBonus.stakingDeposits))
            / Math.pow(10, decimals)).toFixed(4)}
            &nbsp;more {tokens[userBonus.bonusToken].longName} to unlock your bonus!
          </p>
          <Link to="/earn" href="https://www.blum.finance" className="text-teal-500 font-bold">
            Stake now
          </Link>
        </div>
      );
    }

    // They have deposited enough
    return (
      <div className="ml-6">
        <p className="text-2xl">Blum Finance</p>
        <p>The easiest way to grow your crypto!</p>
        {/*<a href="https://www.blum.finance" className="text-teal-500 font-bold">Get started promotional link</a>*/}
      </div>
    );
  }

  if (userInfo.depositBonusAsset && userInfo.depositBonusAmount) {
    // User has selected an asset
    return (
      <div className="ml-6">
        <ClaimBonusModal userInfo={userInfo} open={showModal} setOpen={setShowModal} />
        <p className="text-2xl">Your bonus is waiting</p>
        <p>Your selected free stake position of
          ${userInfo.depositBonusAmount}{tokens[userInfo.depositBonusAsset] &&
            <>in {tokens[userInfo.depositBonusAsset].shortName}</>} is waiting for you.</p>
        <button 
          className="text-teal-500 font-bold"    
          onClick={() => {
            setShowModal(true);
          }}
        >Click here to claim it</button>
      </div>
    );
  }

  // User has not selected an asset
  return (
    <div className="ml-6">
      <ClaimBonusModal userInfo={userInfo} open={showModal} setOpen={setShowModal} />
      <div className="text-3xl">New User Bonus</div>
      <p className="text-2xl">Instantly receive up to a $1,000 staking position on us!</p>
      <button
        className="text-teal-500 font-bold primaryButtonClass"
        onClick={() => {
          setShowModal(true);
        }}
      > Click here to claim it </button>
    </div>
  );
};

function ActionsHeader() {
  const { slowRefresh } = useRefresh();
  const [userBonus, setUserBonus] = React.useState<any>(null);

  const {
    walletAddress,
    getUserBonus,
  } = React.useContext(Web3BombappContext);

  const { userInfo } = React.useContext(BombApiContext);

  useEffect(() => {
    const getUserBonusInfo = async () => {
      const userBonus = await getUserBonus(walletAddress);
      setUserBonus(userBonus);
    };

    getUserBonusInfo();
  }, [slowRefresh]);

  return (
    <div className="w-full p-4 flex items-center">
      <div className="hidden lg:block">
        <Link
          to={"/#/mobile/buy"}
          className={classNames("w-full text-center", primaryButtonClass)}
        >
          Buy Crypto
        </Link>
        <Link
          to={"/#/mobile/deposit"}
          className={classNames("w-full mt-2", primaryButtonClass)}
        >
          Deposit
        </Link>
        <Link
          to={"/#/mobile/withdraw"}
          className={classNames("w-full mt-2", primaryButtonClass)}
        >
          Withdraw
        </Link>
      </div>
      {userBonus && userInfo && (
        <BonusSection userBonus={userBonus} userInfo={userInfo} />
      )}
    </div>
  );
}

export default ActionsHeader;
