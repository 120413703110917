import React from "react";

import Navbar from "components/Navbars/AuthNavbar";
import FooterAuth from "components/Footers/FooterAuth";
import Geofence from "components/Geofence/Geofence";
import { useBreakpoint } from "../hooks/useBreakpoint";

export default function AuthLayout(
  { children, sidebarContent, layout = "centered" }:
    { children: React.ReactNode, sidebarContent?: React.ReactNode, layout?: string; }
) {
  const isLg = useBreakpoint("lg");

  if (isLg && layout === "split") {
    return (
      <>
        <Geofence />
        <main className="grid grid-cols-8 h-full">
          <aside className="bg-[#033246] relative col-span-5">
            {sidebarContent}
          </aside>
          <section className="relative py-20 pb-32 col-span-3">
            <Navbar transparent layout={layout} />
            {children}
            <FooterAuth layout={layout} />
          </section>
        </main>
      </>
    );
  }

  return (
    <>
      <Geofence />
      <Navbar transparent />
      <main className="h-full">
        <section className="relative w-full py-20 lg:py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-[#033246] bg-no-repeat bg-full"
          ></div>
          {children}
          <FooterAuth />
        </section>
      </main>
    </>
  );
}
