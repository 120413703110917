import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import NetworkSelector from "./Components/NetworkSelector";
import QRCode from "react-qr-code";
import copyIcon from "../../../../assets/images/copy_icon.png";
import { BombApiContext, getDepositAssets } from "context/BombApiContext";
import { Web3BombappContext } from "context/Web3Bombapp";
import { notifyFailed, notifySuccess } from "components/Toast";

function DepositCoin() {
  const { address } = useParams();
  const [network, setNetwork] = React.useState<any|null>();
  const [token, setToken] = React.useState<any>(null);
  // const [depositMinimumAmount, setDepositMinimumAmount] = React.useState<any|null>();
  const [depositWalletAddress, setDepositWalletAddress] = React.useState<string|null>(null);
  const { walletAddress } = React.useContext(Web3BombappContext);
  const { getDepositAddress, getDepositAsset } = React.useContext(BombApiContext);
  const [availableNetworks, setAvailableNetworks] = React.useState<any[]>([]);

  useEffect(() => {
    setDepositWalletAddress("");
    if (network) {
      getDepositAddress(network[2], walletAddress).then((response: any) => {
        if (response.error) {
          alert(response.error);
          return;
        }
        setDepositWalletAddress(response.depositAddress.address);
      });
      getDepositAsset(address).then((response: any) => {
        if (response.error) {
          alert(response.error);
          return;
        }
        // setDepositMinimumAmount(response.depositAsset.minimum);
      });
    }
  }, [network]);

  useEffect(() => {
    getDepositAssets().then((assets) => {
      const mappedNetworks = assets.depositAssets.filter((value) => {
        if (!value.blockchain.evmCompatible) {
          return false;
        }

        return value.bombchainAssetContract === address;
      }).map((asset) => {
        if (!token) {
          setToken(asset);
        }

        return asset.blockchain;
      });
      setAvailableNetworks(mappedNetworks);
    });
  }, []);

  if (!token) {
    return null;
  }

  return (
    <div className="App">
      <div className="relative max-w-3xl mx-auto">
        <div>
          <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>
            Deposit {token.assetSymbol} to your Wallet
          </h1>
          <div className="mt-5 text-2xl text-center">
            From which Network?
          </div>
          <div className="mx-3 mt-3">
            <NetworkSelector onChange={(value) => { setNetwork(value); }} networks={availableNetworks} />
          </div>
        </div>
        {depositWalletAddress && (
          <>
            <div
              className="mt-10 mx-auto"
              style={{
                height: "auto",
                maxWidth: 150, width:
                "100%", background:
                "white", padding: "8px"
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={depositWalletAddress}
                viewBox={"0 0 256 256"}
              />
            </div>
            <div className="mx-6 mt-6 text-md font-bold text-black mb-1">
              Deposit your {token.assetSymbol} to the following address on the {network[1]} network
            </div>
            <div className="relative mx-6">
              <input
                className="bg-white rounded-lg rounded text-sm border border-blueGray-300 text-blueGray-600 text-black
                text-xs py-3 text-center w-full px-4"
                readOnly
                value={depositWalletAddress}
              />
              <img
                src={copyIcon} className="w-8 h-8 absolute right-2 top-1 cursor-pointer"
                onClick={() => navigator.clipboard.writeText(depositWalletAddress).then(() => {
                  notifySuccess("Successfully copied");
                })
                  .catch(() => {
                    notifyFailed("Something went wrong");
                  })}
                alt="copy"
              />
            </div>
            <div className="text-left text-black mx-6 mt-3">
              <div className="font-semibold text-sm pb-2">
                Use only this address when making deposits of {token.assetSymbol} to Blum from
                the {network[1]} network. Only send supported assets shown through
                the deposit process.
              </div>
              <div className="font-semibold text-sm ">
                Any tokens sent to a non-{network[1]} compatible address will be lost.</div>
              {/*<div className="font-bold text-sm">*/}
              {/*  Minimum deposit amount: <span className="text-lg">{depositMinimumAmount}
              {token.assetSymbol}</span>*/}
              {/*</div>*/}
            </div>
          </>
        )}
        <div className="mt-5">&nbsp;</div>
      </div>
    </div>
  );
}

export default DepositCoin;
