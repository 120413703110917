import React, { useEffect } from "react";
import AuthLayout from "components/AuthLayout";
import logoLight from "assets/images/blumfinance_logo.png";
import logo from "assets/images/blumfinance_logo_dark.png";
import { useLocation } from "react-router-dom";
import { InjectedProviderExists } from "context/Web3Bombapp";
import ConnectingModal from "components/ConnectWallet/ConnectingModal";
import growImage from "assets/images/grow-image.png";
import plantBottomRight from "assets/images/plant-bottom-right.png";
import plantTopLeft from "assets/images/plant-top-left.png";

const SidebarContent = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  if (searchParams.get("amount") == null || searchParams.get("asset") == null) {
    return (
      <div className="bg-[#033246] h-full">
        <img src={plantTopLeft} alt="Plant Top Left" className="absolute top-0 left-0 w-2/12" />
        <img src={plantBottomRight} alt="Plant Bottom Right" className="absolute bottom-0 right-0 w-2/12" />
        <div className="flex flex-col text-white justify-center items-center h-full">
          <div className="w-6/12 z-10">
            <div className="text-4xl font-medium">
              Welcome to Bl&#252;m Finance
            </div>
            <div className="mt-8 text-2xl font-medium">
              Simply login using the Torus wallet by clicking the Sign In button on the right.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#033246] h-full">
      <img src={plantTopLeft} alt="Plant Top Left" className="absolute top-0 left-0 w-4/12" />
      <img src={plantBottomRight} alt="Plant Bottom Right" className="absolute bottom-0 right-0 w-4/12" />
      <div className="flex flex-col text-white justify-center items-center h-full">
        <div className="w-6/12 z-10">
          <div className="text-4xl font-medium">
            You are about to secure your bonus of ${searchParams.get("amount")} in {searchParams.get("asset")}
          </div>
          <div className="mt-8 text-2xl font-medium">
            Simply login using the Torus wallet by clicking the Sign In button on the right.
          </div>
        </div>
      </div>
    </div>
  );
};

function ConnectWallet(
  {
    web3Connect,
    web3Disconnect,
    web3Connecting
  }: {
    // eslint-disable-next-line no-unused-vars
    web3Connect: (connector: any) => void,
    web3Disconnect: () => void,
    web3Connecting: boolean;
  }
) {
  const search = useLocation().search;
  useEffect(() => {
    const vconsole = new URLSearchParams(search).get("vconsole");
    if (vconsole === "yes") {
      const VConsole = require("vconsole");
      new VConsole({ theme: "dark" });
    }
  }, [search]);

  const searchParams = new URLSearchParams(search);

  return (
    <AuthLayout layout="split" sidebarContent={<SidebarContent />}>
      <ConnectingModal web3Connecting={web3Connecting} web3Disconnect={web3Disconnect} />
      <div className="container mx-auto px-4 h-full relative">
        <div className="flex content-center justify-center h-full">
          <div className="w-full lg:w-3/5 px-4">
            <div className="relative mb-8">
              <img src={logoLight} alt="Logo" className="lg:hidden mx-auto w-11/12" />
              <img src={logo} alt="Logo" className="hidden lg:block mx-auto w-11/12" />
            </div>
            <div
              className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200
            border-0 z-10"
            >
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="hidden lg:block text-blueGray-500 text-sm font-bold">
                    Connect your wallet
                  </h6>
                  <h6 className="lg:hidden text-blueGray-500 text-sm font-bold">
                    {searchParams.get("amount") == null || searchParams.get("asset") == null ? (
                      <>Connect your wallet</>
                    ) : (
                      <div>
                        <div>
                          You are about to secure your bonus
                          of ${searchParams.get("amount")} in {searchParams.get("asset")}.
                        </div>
                        <div className="mt-1">
                          Simply login using the Torus wallet by clicking the Sign In button below.
                        </div>
                      </div>
                    )}
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6
                     py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear
                     transition-all duration-150"
                    type="button"
                    onClick={() => { web3Connect("torus"); } }
                  >
                    Sign in with Torus Wallet
                  </button>
                </div>
                <div className="flex-auto pt-0">
                  <div className="text-blueGray-400 text-center font-bold">
                    <small>
                      Torus allows you to connect using your existing
                      Google, Facebook or other service credentials.
                    </small>
                  </div>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with a different wallet</small>
                </div>
              </div>
              <div className="btn-wrapper text-center pb-5">
                {InjectedProviderExists() && (
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 px-4 py-2 rounded
                      outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex
                      items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => { web3Connect("injected"); }}
                  >
                    {/*<img*/}
                    {/*  alt="..."*/}
                    {/*  className="w-5 mr-1"*/}
                    {/*  src={require("assets/img/github.svg").default}*/}
                    {/*/>*/}
                    Metamask
                  </button>
                )}
                <button
                  className="bg-white active:bg-blueGray-50 text-blueGray-700 px-4 py-2 rounded
                    outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center
                    font-bold text-xs ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => { web3Connect("walletconnect"); }}
                >
                  {/*<img*/}
                  {/*  alt="..."*/}
                  {/*  className="w-5 mr-1"*/}
                  {/*  src={require("assets/img/google.svg").default}*/}
                  {/*/>*/}
                  WalletConnect
                </button>
              </div>
            </div>
            {/*<div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>*/}
          </div>
          <img src={growImage} alt="Grow" className="hidden lg:block w-5/12 absolute bottom-0" />
        </div>
      </div>
    </AuthLayout>
  );
}

export default ConnectWallet;
