/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { isSuccessfulTransaction } from "context/Web3Bombapp";

export default function TransactionModal(
  { transaction, setTransaction }: { transaction: any, setTransaction: (transaction: any) => void; }
) {
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionError, setTransactionError] = useState(null);
  const [transactionReceipt, setTransactionReceipt] = useState(null);

  useEffect(() => {
    if (!transaction) {
      return;
    }

    transaction.on("transactionHash", (hash: any) => {
      setTransactionHash(hash);
    });
    transaction.on("receipt", (receipt: any) => {
      setTransactionReceipt(receipt);
    });
    transaction.on("error", (error: any) => {
      setTransactionError(error);
    });
  }, [transaction]);

  return (
    <Transition.Root show={transaction !== null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-5 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg
                bg-[#033246] w-full shadow-xl transition-all lg:w-1/2 max-w-3xl"
              >
                <div>
                  <div className="mt-3 mb-3 text-center text-white px-2 sm:mt-5">
                    {transactionReceipt || transactionError ? (
                      <>
                        {!transactionError && isSuccessfulTransaction(transactionReceipt) ? (
                          <>
                            <h1 className="text-lg font-bold">Transaction sent</h1>
                            <p className="mb-6">
                              Your{" "}
                              <a
                                className="text-emerald-500"
                                href={"https://www.bombscan.com/tx/" + transactionReceipt.transactionHash}
                                target="_blank" rel="noreferrer"
                              >transaction</a>{" "}
                              has been successfuly sent.
                            </p>
                          </>
                        ) : (
                          <>
                            <h1 className="text-lg font-bold">Transaction failed</h1>
                            <p>This transaction could not be completed.</p>
                            <p className="mb-6">Please try again or contact us if the problem persists.</p>
                          </>
                        )}
                        <button
                          className="bg-[#007D8C] px-24 py-1 rounded-full text-xl font-bold text-black mb-3"
                          onClick={() => {
                            setTransaction(null);
                            setTransactionReceipt(null);
                            setTransactionError(null);
                            setTransactionHash(null);
                          }}
                        >Ok</button>
                      </>
                    ) : (
                      <>
                        <h1 className="text-lg font-bold">Transaction in progress</h1>
                        <p className="mb-6">Waiting for transaction confirmation...</p>
                        {transactionHash && (
                          <>
                            <p  className="text-2xs">Transaction ID:</p>
                            <p className="text-2xs">{transactionHash}</p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
