/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "pages/Mobile/Positions/[token]/Components/DurationSelector";

// @ts-ignore
export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="absolute top-0 z-50 flex flex-wrap items-center justify-between w-full px-2 py-3 navbar-expand-lg">
        <div className="container flex flex-wrap items-center justify-between px-4 mx-auto">
          <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className={classNames(
                "inline-block py-2 mr-4 text-sm font-bold leading-relaxed uppercase whitespace-nowrap",
                props.layout !== "split" ? "text-white" : "text-[#033246]"
              )}
              to="/"
            >
              Blum Finance
            </Link>
            <button
              className="block px-3 py-1 text-xl leading-none bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className={classNames("text-white fas fa-bars", props.layout !== "split" ? "text-white" : "text-[#033246]")}></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="blum-navbar-warning"
          >
            <ul className="flex flex-col mr-auto list-none lg:flex-row">
              {/* <li className="flex items-center">
                <a
                  className="flex items-center px-3 py-4 text-xs font-bold uppercase lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 lg:py-2"
                  href="https://bomb.app/learning-lab/tailwind/react/overview/notus?ref=nr-auth-navbar"
                >
                  <i className="mr-2 text-lg lg:text-blueGray-200 text-blueGray-400 far fa-file-alt leading-lg" />{" "}
                  Docs
                </a>
              </li> */}
            </ul>
            <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
              {/*<li className="flex items-center">*/}
              {/*  <PagesDropdown />*/}
              {/*</li>*/}
              <li className="flex items-center">
                {/* <a
                  className={classNames(
                    "flex items-center px-3 py-4 text-xs font-bold uppercase lg:py-2",
                    props.layout !== "split" ? "lg:text-white lg:hover:text-blueGray-200 text-blueGray-700" : "text-[#033246]"
                  )}
                  href="https://discord.bomb.money"
                  target="_blank"
                >
                  <i className={
                    classNames(
                      "text-lg fab fa-discord leading-lg",
                      props.layout !== "split" ? "lg:text-blueGray-200 text-blueGray-400" : "text-[#033246]"
                    )} />
                  <span className="inline-block ml-2 lg:hidden">Discord</span>
                </a> */}
              </li>
              <li className="flex items-center">
                {/* <a
                  className={classNames(
                    "flex items-center px-3 py-4 text-xs font-bold uppercase lg:py-2",
                    props.layout !== "split" ? "lg:text-white lg:hover:text-blueGray-200 text-blueGray-700" : "text-[#033246]"
                  )}
                  href="https://t.me/bombmoneybsc"
                  target="_blank"
                >
                  <i className={
                    classNames(
                      "text-lg fab fa-telegram leading-lg",
                      props.layout !== "split" ? "lg:text-blueGray-200 text-blueGray-400" : "text-[#033246]"
                    )} />
                  <span className="inline-block ml-2 lg:hidden">Telegram</span>
                </a> */}
              </li>
              <li className="flex items-center">
                {/* <a
                  className={classNames(
                    "flex items-center px-3 py-4 text-xs font-bold uppercase lg:py-2",
                    props.layout !== "split" ? "lg:text-white lg:hover:text-blueGray-200 text-blueGray-700" : "text-[#033246]"
                  )}
                  href="https://www.facebook.com/bombmoney"
                  target="_blank"
                >
                  <i className={
                    classNames(
                      "text-lg fab fa-facebook leading-lg",
                      props.layout !== "split" ? "lg:text-blueGray-200 text-blueGray-400" : "text-[#033246]"
                    )} />
                  <span className="inline-block ml-2 lg:hidden">Share</span>
                </a> */}
              </li>

              <li className="flex items-center">
                <a
                  className={classNames(
                    "flex items-center px-3 py-4 text-xs font-bold uppercase lg:py-2",
                    props.layout !== "split" ? "lg:text-white lg:hover:text-blueGray-200 text-blueGray-700" : "text-[#033246]"
                  )}
                  href="https://twitter.com/BlumFinance"
                  target="_blank"
                >
                  <i className={
                    classNames(
                      "text-lg fab fa-twitter leading-lg",
                      props.layout !== "split" ? "lg:text-blueGray-200 text-blueGray-400" : "text-[#033246]"
                    )} />
                  <span className="inline-block ml-2 lg:hidden">Tweet</span>
                </a>
              </li>

              {/* <li className="flex items-center">
                <a
                  className={classNames(
                    "flex items-center px-3 py-4 text-xs font-bold uppercase lg:py-2",
                    props.layout !== "split" ? "lg:text-white lg:hover:text-blueGray-200 text-blueGray-700" : "text-[#033246]"
                  )}
                  href="https://github.com/creativetimofficial/notus-react?ref=nr-auth-navbar"
                  target="_blank"
                >
                  <i className={
                    classNames(
                      "text-lg fab fa-discord leading-lg",
                      props.layout !== "split" ? "lg:text-blueGray-200 text-blueGray-400" : "text-[#033246]"
                    )} />
                  <span className="inline-block ml-2 lg:hidden">Star</span>
                </a>
              </li> */}

              {/*<li className="flex items-center">
                <button
                  className={classNames(
                    "flex items-center px-3 py-4 text-xs font-bold uppercase lg:py-2",
                    props.layout !== "split" ? "lg:text-white lg:hover:text-blueGray-200 text-blueGray-700" : "text-[#033246]"
                  )}
                  type="button"
                >
                  <i className="fas fa-arrow-alt-circle-down"></i> Download
                </button>
              </li>*/}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
