import React from "react";
import logo from "assets/images/blumfinance_logo.png";
import AuthLayout from "components/AuthLayout";

function Offline() {

  return (
    <AuthLayout>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative mb-8">
              <img src={logo} alt="Logo" className="w-full mx-auto w-11/12" />
            </div>
            <div
              className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200
            border-0"
            >
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    You are currently offline.
                  </h6>
                </div>
                <div className="flex-auto pt-0">
                  <div className="text-blueGray-400 text-center font-bold">
                    <small>
                      In order to interact with the Blum Finance Web3 app,
                      you need to be connected to the Internet.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Offline;
