import React from "react";
import toast from "react-hot-toast";

export const notifyInfo = (text: string) =>
  toast.custom(
    (tt) => (
      <div
        className={`bg-blue-500 text-white px-6 py-4 shadow-md rounded-sm ${
          tt.visible ? "animate-enter" : "animate-leave"
        }`}
      >
        <p className="text-sm font-light">{text}</p>
      </div>
    ),
    { position: "bottom-right" }
  );

export const notifySuccess = (text: string) =>
  toast.custom(
    (tt) => (
      <div
        className={`bg-green-500 text-white px-6 py-4 shadow-md rounded-sm ${
          tt.visible ? "animate-enter" : "animate-leave"
        }`}
      >
        <p className="text-sm font-light">{text}</p>
      </div>
    ),
    { position: "bottom-right" }
  );
export const notifyFailed = (text: string) =>
  toast.custom(
    (tt) => (
      <div
        className={`bg-red-500 text-white px-6 py-4 shadow-md rounded-sm ${
          tt.visible ? "animate-enter" : "animate-leave"
        }`}
      >
        <p className="text-sm font-light">{text}</p>
      </div>
    ),
    { position: "bottom-right" }
  );
