import React from "react";
import Lottie from "lottie-react";
import spinner from "assets/images/bomb-spinner.json";

// eslint-disable-next-line no-unused-vars
function Loading({ type }: { type: string }) {

  return (
    <div className="App">
      <div className="relative w-64 h-64 -mb-8 mx-auto">
        <Lottie animationData={spinner} loop={true} />
      </div>
    </div>
  );
}

export default Loading;
