import React, { useEffect } from "react";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface NetworkSelectorProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  networks: any[];
  defaultValue?: any;
}

export default function NetworkSelector({ defaultValue = null, onChange, networks }: NetworkSelectorProps) {
  const [selected, setSelected] = useState(defaultValue);
  const [mappedNetworks, setMappedNetworks] = useState<any[]>([]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    setMappedNetworks(networks.map(network => {
      return [network.chainId, network.name, network.slug];
    }));
  }, [networks]);

  if (mappedNetworks.length === 0) {
    return null;
  }

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className="space-y-4">
        {mappedNetworks.map((network) => (
          <RadioGroup.Option
            key={network[1]}
            value={network}
            className={({ checked, active }) =>
              classNames(
                active || checked || (selected && selected[0] === network[0]) ?
                  "bg-[#3d7b95] text-white" :
                  "bg-white text-[#3d7b95]",
                "relative block cursor-pointer rounded-lg p-3 focus:outline-none justify-between " +
                "bg-transparent border border-solid border-[#3d7b95]"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    active || checked ? "" : "",
                    "flex flex-col text-sm text-center w-full")}
                >
                  <RadioGroup.Label as="span" className="font-bold">
                    {network[1]}
                  </RadioGroup.Label>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
