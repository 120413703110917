import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useRefresh from "../../../../hooks/useRefresh";
import { Web3BombappContext } from "../../../../context/Web3Bombapp";
import { BeefyContext } from "../../../../context/BeefyContext";
import { primaryButtonClass, tokens } from "pages/Mobile/Earn";
import { PositionType } from "../../../../../types/Position";
import TransactionModal from "./Components/TransactionModal";
import { classNames } from "./Components/DurationSelector";

function Refund() {
  const { address, tokenId } = useParams();
  const { slowRefresh } = useRefresh();
  const { stakeInfo, freeEarlyWithdraw } = React.useContext(Web3BombappContext);
  const { fetchPrice } = React.useContext(BeefyContext);

  if (!tokens[address]) {
    return <div>Invalid token</div>;
  }

  const [position, setPosition] = React.useState<PositionType | null>(null);
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    const decimals = 18;
    stakeInfo(tokens[address].stakeAddress, tokenId).then((position: any) => {
      const amount = Number(position.amountStaked) / Math.pow(10, decimals);
      setPosition({
        token: tokens[address],
        tokenId: Number(tokenId),
        amount: amount,
        allowWithdrawEarly: Boolean(position.allowWithdrawEarly),
        assetTransferred: Boolean(position.assetTransferred),
        apr: Number(position.apr) / 100,
        created: Number(position.created),
        lockTime: Number(position.lockTime),
        dollarValue: fetchPrice ? fetchPrice({ id: tokens[address].beefySymbol }) * amount : null,
      });
    });
  }, [fetchPrice, slowRefresh]);

  if (!position) {
    return null;
  }

  return (
    <div className="App">
      <TransactionModal transaction={transaction} setTransaction={setTransaction} />
      <div className="relative max-w-3xl">
        <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>Refund Stake</h1>
        <div>
          <div className="text-3xl font-bold text-green-300">${position.dollarValue.toFixed(2)}</div>
          <div className="text-3xl font-bold text-white">{position.amount} {tokens[address].shortName}</div>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
        <div className="flex bg-[#202126] text-white font-bold rounded-full p-3 px-5 justify-items-center mt-3">
          <div className="text-left grow">
            Amount Received
          </div>
          <div className="text-right">{position.amount} {tokens[address].shortName}</div>
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-500" />
        <div className="mt-5">
          <button
            className={classNames("w-full", primaryButtonClass)}
            onClick={() => {
              (async() => {
                const tx = freeEarlyWithdraw(address, position.tokenId);
                setTransaction(tx);
              })();
            }}
          >Confirm Refund</button>
        </div>
      </div>
    </div>
  );
}

export default Refund;
