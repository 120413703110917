import React, { useEffect } from "react";
import { Web3BombappContext } from "context/Web3Bombapp";
import useRefresh from "../hooks/useRefresh";

interface InputFromWalletProps {
  token: any;
  defaultValue: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
}

export default function InputFromWallet({ token, defaultValue, onChange }: InputFromWalletProps) {
  const [value, setValue] = React.useState(defaultValue);
  const { fastRefresh } = useRefresh();
  const [balance, setBalance] = React.useState(0);
  const { getBalance } = React.useContext(Web3BombappContext);

  useEffect(() => {
    const address = token.address ?? token.bombchainAssetContract;
    if (!address) {
      throw new Error("No address found");
    }
    getBalance(address).then((balance: number) => {
      setBalance(balance);
    });
  }, [fastRefresh]);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const handleSetMax = () => {
    setValue(balance.toString());
    onChange(balance.toString());
  };

  return (
    <div className="flex items-center">
      <div className="relative grow flex items-center rounded-full bg-gray-200">
        <input
          type="text"
          className="grow bg-transparent border-none
           text-right py-3 text-black" placeholder="Enter Amount"
          value={value ?? ""}
          onChange={handleChange}
        />
        <div
          className="ml-1 mr-3 text-black"
        >{token.shortName}</div>
      </div>
      <div
        className="ml-3 text-[#007D8C] font-bold cursor-pointer"
        onClick={handleSetMax}
      >Max</div>
      {/*<div className="float-right mt-2 mr-4"><span className="text-gray-500">Available:</span> {balance}BTC</div>*/}
    </div>
  );
}
