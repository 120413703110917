import { Disclosure, Transition } from "@headlessui/react";
import { classNames } from "pages/Mobile/Positions/[token]/Components/DurationSelector";
import TokenSymbol from "components/TokenSymbol";
import React from "react";
import { primaryButtonClass } from "pages/Mobile/Earn";
import { TokenType } from "types/Token";
import CreateStake from "components/Earn/CreateStake";

function TokenRow({ token }: { token: TokenType}) {
  return (
    <Disclosure defaultOpen={false}>
      {() => (
        <>
          <Disclosure.Button as="tr" className="hover:bg-gray-100">
            <td className="mt-2 mb-3 flex items-center justify-start">
              <div>
                <TokenSymbol address={token.address} className="inline w-12 h-12" />
              </div>
              <div className="w-1/3 lg:w-2/3 ml-4 text-left">
                <div className="text-base font-semibold text-black">{token.longName}</div>
              </div>
            </td>
            {token.aprs.map((apr, index) => (
              <td key={index} className="text-[#033246] text-center hidden lg:table-cell">
                <div className="text-2xl font-bold">{apr / 100}%</div>
              </td>
            ))}
            <td className="text-[#033246] text-center lg:hidden">
              <div className="text-2xl font-bold">{token.highestApr}%</div>
            </td>
            <td className="flex">
              <div className={classNames(primaryButtonClass, "mx-auto cursor-pointer hidden lg:block")}>
                Earn on {token.shortName}
              </div>
            </td>
          </Disclosure.Button>
          <Transition
            as="tr"
            className="transition-all duration-500 overflow-hidden"
            enterFrom="transform scale-95 opacity-0 max-h-0"
            enterTo="transform scale-100 opacity-100 max-h-96"
            leaveFrom="transform scale-100 opacity-100 max-h-96"
            leaveTo="transform scale-95 opacity-0 max-h-0"
          >
            <Disclosure.Panel
              as="td"
              className="text-sm text-gray-500"
              colSpan={6}
            >
              <div className="border-2 border-emerald-800 rounded-lg w-full lg:left-0">
                <div className="lg:mx-auto py-4 px-5">
                  {/*<div className="flex justify-between gap-4">
                    <Link
                      to={"/#/mobile/positions/" + token.address + "/buy"}
                      className={classNames("w-full relative", primaryButtonClass)}
                    >
                      <div className="-mt-3 text-center lg:text-left lg:mt-0">
                        <img
                          src={visaMC}
                          className="w-16 mx-auto hidden sm:inline aling-middle mr-2"
                          alt="Visa Mastercard"
                        >
                        </img>
                        Buy {token.shortName}
                      </div>
                      <div className="absolute w-full left-0">
                        <img
                          src={visaMC}
                          className="w-16 mx-auto sm:hidden"
                          alt="Visa Mastercard"
                        >
                        </img>
                      </div>
                    </Link>
                    <Link
                      to={"/#/mobile/positions/" + token.address + "/deposit"}
                      className={classNames("w-full", primaryButtonClass)}
                    >
                      Transfer {token.shortName}
                    </Link>
                  </div>*/}
                  <CreateStake token={token} />
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

export default TokenRow;
