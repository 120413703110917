const plugin = require("tailwindcss/plugin");
const colors = require("tailwindcss/colors");

module.exports = {
  mode: "jit",
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    colors: {
      ...colors,
    },
    extend: {
      fontSize: {
        "2xs": "0.65rem",
        55: "55rem",
      },
      fontFamily: {
        ps2p: ["\"Press Start 2P\"", "cursive"],
      },
      colors: {
        "primary-green": "#00E352",
        "primary-green-light": "#A9FA71",
        "primary-grey": "#292929",
        "primary-wine-dark": "#23062C",
        "bomb-yellow": "#e7d667",
        "bomb-asset-row": "#9a820040",
        "bomb-asset-row-hover": "rgb(113 63 18)"
      },
      minHeight: {
        "screen-75": "75vh",
      },
      opacity: {
        80: ".8",
      },
      zIndex: {
        2: 2,
        3: 3,
      },
      inset: {
        "-100": "-100%",
        "-225-px": "-225px",
        "-160-px": "-160px",
        "-150-px": "-150px",
        "-94-px": "-94px",
        "-50-px": "-50px",
        "-29-px": "-29px",
        "-20-px": "-20px",
        "25-px": "25px",
        "40-px": "40px",
        "95-px": "95px",
        "145-px": "145px",
        "195-px": "195px",
        "210-px": "210px",
        "260-px": "260px",
      },
      height: {
        "95-px": "95px",
        "70-px": "70px",
        "350-px": "350px",
        "500-px": "500px",
        "600-px": "600px",
      },
      maxHeight: {
        "860-px": "860px",
      },
      maxWidth: {
        "100-px": "100px",
        "120-px": "120px",
        "150-px": "150px",
        "180-px": "180px",
        "200-px": "200px",
        "210-px": "210px",
        "580-px": "580px",
      },
      minWidth: {
        "140-px": "140px",
        48: "12rem",
      },
      backgroundSize: {
        full: "100%",
      },
    },
  },
  variants: [
    "responsive",
    "group-hover",
    "focus-within",
    "first",
    "last",
    "odd",
    "even",
    "hover",
    "focus",
    "active",
    "visited",
    "disabled",
  ],
  plugins: [
    require("@tailwindcss/forms"),
    plugin(function ({ addComponents, theme }) {
      const screens = theme("screens", {});
      addComponents([
        {
          ".container": { width: "100%" },
        },
        {
          [`@media (min-width: ${screens.sm})`]: {
            ".container": {
              "max-width": "640px",
            },
          },
        },
        {
          [`@media (min-width: ${screens.md})`]: {
            ".container": {
              "max-width": "768px",
            },
          },
        },
        {
          [`@media (min-width: ${screens.lg})`]: {
            ".container": {
              "max-width": "1024px",
            },
          },
        },
        {
          [`@media (min-width: ${screens.xl})`]: {
            ".container": {
              "max-width": "1280px",
            },
          },
        },
        {
          [`@media (min-width: ${screens["2xl"]})`]: {
            ".container": {
              "max-width": "1280px",
            },
          },
        },
      ]);
    }),
    plugin(function ({ addUtilities, theme }) {
      const defighter = {
        ".ps2p-8": {
          fontFamily: theme("fontFamily.ps2p"),
          fontStyle: "normal",
          fontWeight: "regular",
          fontSize: "8px",
          lineHeight: "16.5px",
        },
        ".ps2p-11": {
          fontFamily: theme("fontFamily.ps2p"),
          fontStyle: "normal",
          fontWeight: "regular",
          fontSize: "11px",
          lineHeight: "27.5px",
        },
        " .dfTransition": {
          transition: "all 0.2s linear 0s",
        },
      };

      addUtilities(defighter, ["responsive"]);
    }),
  ],
};
