import React from "react";
import CreateStake from "components/Earn/CreateStake";
import { tokens } from "pages/Mobile/Earn";
import { useParams } from "react-router-dom";
import TokenSymbol from "components/TokenSymbol";

function New() {
  const { address } = useParams();
  const token = tokens[address];
  
  return (
    <div className="App">
      <div className="relative max-w-3xl">
        <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>Create Stake</h1>
        <div className="flex items-center mt-5">
          <div className="w-1/3">
            <TokenSymbol address={token.address} className="inline w-12 h-12" />
            &nbsp; <span className="text-lg font-semibold">BTC</span>
          </div>
          <div className="w-2/3">
            <div className="text-sm font-semibold text-gray-500">ANNUALIZED INTEREST RATE</div>
            {/*<div className="text-3xl font-bold text-green-300">{duration ? duration[0] / 100 : ""}%</div>*/}
          </div>
        </div>
        <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-500" />
        <CreateStake token={token} />
      </div>
    </div>
  );
}

export default New;
