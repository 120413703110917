import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from "react-responsive-carousel";
import { TokenType } from "../../../types/Token";
import useRefresh from "../../hooks/useRefresh";
import { Web3BombappContext } from "context/Web3Bombapp";
import TokenRow from "components/Earn/TokenRow";
import Loading from "pages/Loading";
import ActionsHeader from "components/Home/ActionsHeader";
import { getStakeAssets } from "context/BombApiContext";
import Web3 from "web3";

export const tokens: { [key: string]: TokenType } = {
  "0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24": {
    longName: "Bitcoin",
    shortName: "BTC",
    beefySymbol: "BTCB",
    onrampNetwork: "MATIC",
    onrampNetworkSlug: "polygon",
    onrampSymbol: "WBTC_POLYGON",
    bgColor: "#f7931a",
    comingSoon: false,
    address: "0x140F62aCCC69cb24eABdC0E00b7caaC577cA5b24",
    stakeAddress: "0x4F9371e5F4678c4C48b5d51C24be1A6Def20A904",
    image: "https://swap.bomb.app/images/tokens/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c.png"
  },
  "0x128E2b86F42BDB3B646D9B9666B8F8E9cdcEC495": {
    longName: "ETHEREUM",
    shortName: "ETH",
    beefySymbol: "ETH",
    onrampNetwork: "MATIC",
    onrampNetworkSlug: "polygon",
    onrampSymbol: "WETH_POLYGON",
    bgColor: "#12100B",
    comingSoon: false,
    address: "0x128E2b86F42BDB3B646D9B9666B8F8E9cdcEC495",
    stakeAddress: "0xA19A12f0b37267f0810F9Cd66Fe4dcC7d59a47d1",
    // eslint-disable-next-line max-len
    image: "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  },
  "0xfA74D6D13575751C2eb3d4960CD2A164303893d2": {
    longName: "BNB Coin",
    shortName: "BNB",
    beefySymbol: "WBNB",
    onrampNetwork: "BNB",
    onrampNetworkSlug: "binance",
    onrampSymbol: "BNB",
    bgColor: "#f3ba2f",
    comingSoon: false,
    address: "0xfA74D6D13575751C2eb3d4960CD2A164303893d2",
    stakeAddress: "0xa256b4f62E32B1f0B97c49ce5634633a32Cc3F56",
    // eslint-disable-next-line max-len
    image: "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xB8c77482e45F1F44dE1745F52C74426C631bDD52/logo.png",
  },
  "0x59D252E2d9782Df09684b23795265181443677F7": {
    longName: "POLYGON",
    shortName: "MATIC",
    beefySymbol: "MATIC",
    onrampNetwork: "MATIC",
    onrampNetworkSlug: "polygon",
    onrampSymbol: "MATIC",
    bgColor: "#8247E5",
    comingSoon: false,
    address: "0x59D252E2d9782Df09684b23795265181443677F7",
    stakeAddress: "0x8385FF517AeB287f461D339cb16f0f6744114d26",
    // eslint-disable-next-line max-len
    image: "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png",
  },
  "0xF74B0E2c1774D63453cB9A37ae2d634774fd0E54": {
    longName: "AVALANCHE",
    shortName: "AVAX",
    beefySymbol: "AVAX",
    onrampNetwork: "AVAXC",
    onrampNetworkSlug: "avalanche",
    onrampSymbol: "AVAXC",
    bgColor: "#E84142",
    comingSoon: false,
    address: "0xF74B0E2c1774D63453cB9A37ae2d634774fd0E54",
    stakeAddress: "0xaF7dD12f674cc4f8d7522eE056841E3452d9F073",
    // eslint-disable-next-line max-len
    image: "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/avalanchec/assets/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo.png",
  },
};

export const primaryButtonClass = "block bg-[#3D7B95] text-white text-center active:bg-emerald-900 font-bold " +
  "px-7 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none ease-linear transition-all " +
  "text-md duration-150";

export const outlineButtonClass = "text-emerald-800 bg-transparent border-2 border-solid border-emerald-800 " +
  "hover:bg-[#3D7B95] hover:text-white active:bg-emerald-900 font-bold px-2 md:px-7 py-3 rounded " +
  "outline-none focus:outline-none ease-linear transition-all duration-150";

function Earn() {
  const [tokensWithInfo, setTokensWithInfo] = React.useState<TokenType[]>([]);
  const { getAllLockOptions } = React.useContext(Web3BombappContext);
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    getStakeAssets().then((stakeAssets) => {
      Promise.all(Object.values(stakeAssets.stakeAssets).filter(asset => asset.enabled).map((asset) => {
        const token = tokens[Web3.utils.toChecksumAddress(asset.assetContract)];
        if (token.comingSoon) {
          return Promise.resolve(null);
        }
        return getAllLockOptions(token.stakeAddress);
      })).then((stakingInfoArray: any) => {
        setTokensWithInfo(
          Object.values(stakeAssets.stakeAssets)
            .filter(asset => asset.enabled)
            .map((asset, index: number): TokenType => {
              const token = tokens[Web3.utils.toChecksumAddress(asset.assetContract)];
              if (!stakingInfoArray[index]) {
                return token;
              }

              const aprs = stakingInfoArray[index].map((stakingInfo: any): number => {
                return Number(stakingInfo[0]);
              });
              return {
                ...token,
                aprs,
                highestApr: Math.max(...aprs) / 100,
              };
            }));
      });
    });
  }, [slowRefresh]);

  const aprLabels = [
    "90 days",
    "180 days",
    "270 days",
    "360 days",
  ];

  if (tokensWithInfo.length === 0) {
    return <Loading type="Loading" />;
  }

  return (
    <>
      <div className="bg-[#EEF6FA] block mb-3">
        <ActionsHeader />
      </div>
      <div className="App">
        <div className="relative">
          <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>Earn</h1>
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-xs text-left text-gray-300 align-top">Pool</th>
                {aprLabels.map((label, index) => (
                  <th className="hidden text-center lg:table-cell" key={index}>
                    <div className="text-xs text-gray-300">APR</div>
                    <div className="text-sm">{label}</div>
                  </th>
                ))}
                <th className="text-xs text-center text-gray-300 lg:hidden">Earn up to</th>
                <th className="hidden text-left lg:table-cell">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {tokensWithInfo.map((token, index) => (
                <TokenRow token={token} key={index} />
              ))}
            </tbody>
          </table>
          {/*<HowTostake />*/}
          {/*<div className="py-4">
            <Link
              to={"/#/mobile/refer"}
              className={classNames("max-w-3xl lg:mx-auto", primaryButtonClass)}
            >
              Refer a friend to earn $25
            </Link>
          </div>*/}
        </div>
      </div>
    </>
  );
}

export default Earn;
