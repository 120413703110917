import { outlineButtonClass, primaryButtonClass } from "pages/Mobile/Earn";
import InputFromWallet from "components/InputFromWallet";
import DurationSelector, { classNames } from "pages/Mobile/Positions/[token]/Components/DurationSelector";
import React, { useEffect, useState } from "react";
import useRefresh from "../../hooks/useRefresh";
import { BombApiContext } from "context/BombApiContext";
import { Web3BombappContext } from "context/Web3Bombapp";
import TransactionModal from "pages/Mobile/Positions/[token]/Components/TransactionModal";
import TermsModal from "pages/Mobile/Positions/[token]/Components/TermsModal";
import { TokenType } from "../../../types/Token";
import { TokenWithBalanceType } from "pages/Home";
import { BeefyContext } from "context/BeefyContext";
import BuyModal from "pages/Mobile/Positions/[token]/Components/BuyModal";
import moment from "moment";
import Link from "components/Link";

function CreateStake({ token }: { token: TokenType}) {
  const { fastRefresh } = useRefresh();
  const [balance, setBalance] = React.useState<TokenWithBalanceType | null>(null);
  const [allowance, setAllowance] = React.useState<number>(0);
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);
  const [coupon, setCoupon] = React.useState<any>(null);
  const [eligibleForCoupon, setEligibleForCoupon] = React.useState<boolean>(false);
  const [referralMinAmount, setReferralMinAmount] = React.useState<number>(0);
  const [showCouponField, setShowCouponField] = React.useState<boolean>(false);
  const { userInfo, getUserInfoByReferralCode } = React.useContext(BombApiContext);
  const { fetchPrice } = React.useContext(BeefyContext);
  const {
    getBalance,
    walletAddress,
    getReferralMinAmount,
    getAllowance,
    approveContract,
    createStake,
    createStakeWithReferral,
    hasReceivedBonus
  } = React.useContext(Web3BombappContext);
  const stakeContractAddress = token.stakeAddress;
  const spender = "0x167441fF9fe49dC5cC316426e960f8336D0639dF";

  useEffect(() => {
    getBalance(token.address).then((balance: number) => {
      setBalance({
        token: token,
        walletBalance: balance,
        walletBalanceUsd: fetchPrice ? fetchPrice({ id: token.beefySymbol }) * balance : null,
      });
    });
  }, [fastRefresh]);

  useEffect(() => {
    getAllowance(token.address, spender).then((allowance: number) => {
      setAllowance(allowance);
    });
    hasReceivedBonus(stakeContractAddress, walletAddress).then((hasReceivedBonus: boolean) => {
      setEligibleForCoupon(!hasReceivedBonus);
    });
    getReferralMinAmount(stakeContractAddress).then((referralMinAmount: number) => {
      setReferralMinAmount(referralMinAmount);
    });
  }, [fastRefresh]);

  const [amount, setAmount] = React.useState<string | null>();
  const [duration, setDuration] = React.useState<any | null>();

  const [buyOpen, setBuyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [transaction, setTransaction] = useState(null);

  const now = moment();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!amount || !duration) {
          alert("Please select an amount and duration");
          return;
        }

        if (!acceptTerms) {
          alert("Please accept the terms and conditions before staking");
          return;
        }

        if (allowance <= 0) {
          (async() => {
            const tx = approveContract(token.address, spender);
            setTransaction(tx);
          })();
          return;
        }

        (async () => {
          const hasCoupon = coupon || (userInfo && userInfo.referredBy);
          if (eligibleForCoupon && hasCoupon && Number(amount) >= Number(referralMinAmount)) {
            let referrerInfo;
            if (coupon) {
              referrerInfo = await getUserInfoByReferralCode(coupon);
              if (referrerInfo.error) {
                alert("Invalid referral code");
                return;
              }

              if (referrerInfo.user.wallet === walletAddress) {
                alert("You cannot use your own referral code");
                return;
              }
            } else if (userInfo && userInfo.referredBy) {
              referrerInfo = { user: userInfo.referredBy };
            } else {
              throw new Error("Invalid state");
            }

            const tx = createStakeWithReferral(
              token.stakeAddress,
              amount,
              duration.index,
              referrerInfo.user.wallet
            );
            setTransaction(tx);
          } else {
            const tx = createStake(token.stakeAddress, amount, duration.index);
            setTransaction(tx);
          }
        })();
      }}
    >
      <TransactionModal transaction={transaction} setTransaction={setTransaction} />
      <BuyModal open={buyOpen} setOpen={setBuyOpen} token={token} />
      <TermsModal open={termsOpen} setOpen={setTermsOpen} url="https://blum.finance/terms" />
      <TermsModal open={privacyOpen} setOpen={setPrivacyOpen} url="https://blum.finance/privacy" />
      {eligibleForCoupon && userInfo && userInfo.referredBy && (
        <div className="mb-4 p-2  text-center text-[#033246] rounded-full">
          <div>Great, looks like you have been referred! Deposit $100 
            ({referralMinAmount} {token.shortName}) to get your free position!</div>
        </div>
      )}
      <div>
        <DurationSelector stakeContract={token.stakeAddress} onChange={(value) => { setDuration(value); }} />
      </div>
      <div className="mt-5 flex items-center lg:hidden">
        <div className="mb-1 text-left text-gray-400">APR</div>
        <div className="ml-auto">{duration ? duration[0] / 100 : ""}%</div>
      </div>
      <div className="mt-5 flex items-center">
        <div className="mb-1 text-left text-gray-400">Current balance</div>
        <div className="ml-auto">{balance ? balance.walletBalance : ""} {token.shortName}</div>
        <Link
          to={"/#/mobile/positions/" + token.address + "/buy"}
          className="text-[#007D8C] ml-5 font-bold"
          onClick={(e: any) => {
            e.preventDefault();
            setBuyOpen(true);
          }}
        >Buy</Link>
      </div>
      <div className="mt-5 flex items-center">
        <div className="mb-1 mr-3 text-left text-gray-400">Staking Amount</div>
        <div className="grow">
          <InputFromWallet
            token={token}
            defaultValue={amount}
            onChange={(value) => { setAmount(value); }}
          />
        </div>
      </div>
      {eligibleForCoupon && (!userInfo || !userInfo.referredBy) && (
        <>
          {!showCouponField ? (
            <div className="mt-8 text-emerald-500 text-sm text-right">
              <a
                href={undefined}
                onClick={() => setShowCouponField(true)}
                className="block cursor-pointer"
              >Have a referral code?</a>
            </div>
          ) : (
            <div className="mt-8">
              <div className="mb-1 ml-5 text-left text-gray-400">Referral code</div>
              <input
                type="text"
                className="w-full px-5 py-3 text-black border-b border-gray-500" placeholder="Enter referral code"
                value={coupon ?? ""}
                onChange={(e) => setCoupon(e.target.value)}
              />
            </div>
          )}
        </>
      )}
      {duration && amount && Number(amount) > 0 && (
        <div className="mt-5">
          <div className="mb-2 text-center text-emerald-700">
            You will earn {Number(amount) * duration[0] / 10000} {token.shortName},
            unlockable on {now.add(duration[1], "seconds").format("lll")}
          </div>
        </div>
      )}
      <div className="mt-3">
        <div className="flex justify-center items-center">
          <input
            className="form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white checked:bg-emerald-500
                    border-emerald-500 focus:outline-none transition duration-200 mt-1 align-top
                    bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer text-white accent-emerald-500"
            type="checkbox"
            value=""
            id="acceptStakeTerms"
            checked={acceptTerms}
            onChange={(e) => setAcceptTerms(e.target.checked)}
          />
          <label
            className="inline-block text-black form-check-label"
            style={{fontSize:"13px"}}
            htmlFor="acceptStakeTerms"
          >
            I have read and agree to the
            <a
              href="https://blum.finance/terms"
              target="_blank"
              className="text-[#007D8C] text-sm text-right" rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setTermsOpen(true);
              }}
            > terms of service
            </a> & <a
              href="https://blum.finance/privacy"
              target="_blank"
              className="text-[#007D8C] text-sm text-right" rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setPrivacyOpen(true);
              }}
            > privacy policy
            </a>
          </label>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-2 gap-5">
        <button
          className={classNames("w-full", outlineButtonClass)}
          onClick={(e) => {
            e.preventDefault();
            setBuyOpen(true);
          }}
        >
          Buy more {token.shortName}
        </button>
        <button className={classNames("w-full", primaryButtonClass)}>
          {allowance > 0 ? "Stake Now" : "Approve"}
        </button>
      </div>
    </form>
  );
}

export default CreateStake;