import React, { useEffect } from "react";
import { PositionType } from "../../types/Position";
import { tokens } from "./Mobile/Earn";
import useRefresh from "../hooks/useRefresh";
import { Web3BombappContext } from "context/Web3Bombapp";
import { BeefyContext } from "context/BeefyContext";
import { TokenType } from "../../types/Token";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import MyPortfolio from "components/Home/MyPortfolio";
import ActionsHeader from "components/Home/ActionsHeader";
import CurrentStakes from "components/Home/CurrentStakes";

export interface TokenWithBalanceType {
  token: TokenType;
  walletBalance?: number;
  walletBalanceUsd?: number;
}

function Home() {
  const { slowRefresh } = useRefresh();
  const { getAllUserOwned, stakeInfo, getBalance } = React.useContext(Web3BombappContext);
  const [walletAssets, setWalletAssets] = React.useState<TokenWithBalanceType[]|null>(null);
  const [positions, setPositions] = React.useState<PositionType[]|null>(null);
  const { fetchPrice } = React.useContext(BeefyContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isLanding = location.pathname === "/";

  useEffect(() => {
    Promise.all(Object.values(tokens).map((token: TokenType) => {
      if (token.comingSoon) {
        return Promise.resolve(null);
      }
      return getBalance(token.address);
    })).then((balancesArray: any) => {
      setWalletAssets(Object.values(tokens).map((token: TokenType, index: number): TokenWithBalanceType => {
        if (!balancesArray[index]) {
          return { token, walletBalance: 0, walletBalanceUsd: 0 };
        }
        return {
          token,
          walletBalance: balancesArray[index],
          walletBalanceUsd: fetchPrice ? fetchPrice({ id: token.beefySymbol }) * balancesArray[index] : null,
        };
      }));
    });

    const getPositionsForToken = async (token: TokenType): Promise<PositionType[]> => {
      const getAllUserOwnedTokens = await getAllUserOwned(token.stakeAddress);
      const tokenIds: number[] = [];
      const stakeInfoTokens = await Promise.all(getAllUserOwnedTokens.map((tokenId: number) => {
        tokenIds.push(tokenId);
        return stakeInfo(token.stakeAddress, tokenId);
      }));

      const decimals = 18;
      const positions = [];
      let i = 0;
      for (const position of stakeInfoTokens) {
        const amount = Number(position.amountStaked) / Math.pow(10, decimals);
        positions.push({
          token: token,
          tokenId: tokenIds[i++],
          amount: amount,
          allowWithdrawEarly: Boolean(position.allowWithdrawEarly),
          assetTransferred: Boolean(position.assetTransferred),
          apr: Number(position.apr) / 100,
          created: Number(position.created),
          lockTime: Number(position.lockTime),
          dollarValue: fetchPrice ? fetchPrice({ id: token.beefySymbol }) * amount : null,
        });
      }

      return positions;
    };

    Promise.all(Object.values(tokens).map((token: TokenType) => {
      if (token.comingSoon) {
        return Promise.resolve(null);
      }
      return getPositionsForToken(token);
    })).then((positions: PositionType[][]) => {
      setPositions(positions.flat());
    });
  }, [fetchPrice, slowRefresh]);

  useEffect(() => {
    if (!isLanding) {
      return;
    }

    if (positions === null || walletAssets === null) {
      return;
    }

    // @ts-ignore
    const prefix = window.bombRouterType === "hash" ? "/mobile" : "";
    if (positions.length === 0) {
      navigate(prefix + "/earn");
    } else {
      navigate(prefix + "/home");
    }
  }, [positions, walletAssets, isLanding]);

  if (isLanding) {
    return <Loading type="Loading" />;
  }

  if (!walletAssets || !positions) {
    return <Loading type="Loading" />;
  }

  const stakedAssetTotal = positions.reduce(
    (accumulator, currentValue) => accumulator + currentValue.dollarValue,
    0
  ).toFixed(2);
  // const walletAssetsTotal = walletAssets.reduce(
  //   (accumulator, currentValue) => accumulator + currentValue.walletBalanceUsd,
  //   0
  // ).toFixed(2);

  const stakedTotalsPerAsset: { [key: string]: {token: TokenType; amount: number; dollarValue: number}; } = {};
  for (const position of positions) {
    if (!stakedTotalsPerAsset[position.token.address]) {
      stakedTotalsPerAsset[position.token.address] = { token: position.token, amount: 0, dollarValue: 0 };
    }
    stakedTotalsPerAsset[position.token.address].amount += position.amount;
    stakedTotalsPerAsset[position.token.address].dollarValue += position.dollarValue;
  }

  return (
    <div className="lg:flex">
      <div className="bg-white p-5">
        <MyPortfolio
          portfolioAssetsTotal={/*Number(walletAssetsTotal) + */Number(stakedAssetTotal)}
          stakedTotalsPerAsset={stakedTotalsPerAsset}
          walletAssets={walletAssets}
        />
      </div>
      <div className="lg:ml-3 grow">
        <div className="bg-[#EEF6FA]"><ActionsHeader /></div>
        <div className="mt-5 bg-white"><CurrentStakes positions={positions} /></div>
      </div>
    </div>
  );
}

export default Home;
