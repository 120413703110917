import React, { useEffect } from "react";
import { Web3BombappContext } from "context/Web3Bombapp";

function abbreviateAddress(walletAddress: string, beforeLength: number = 6, afterLength: number = 4): string {
  return walletAddress.substring(0, beforeLength) + "..." +
    walletAddress.substring(walletAddress.length - afterLength, walletAddress.length);
}

const UserDropdown = () => {
  const { walletAddress, web3Disconnect } = React.useContext(Web3BombappContext);

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef: any = React.createRef();
  const dropdownMenuRef: any = React.createRef();
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as Node) && dropdownPopoverShow) {
        closeDropdownPopover();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [btnDropdownRef, dropdownMenuRef]);

  if (!walletAddress) {
    return;
  }

  return (
    <>
      {/*{walletAddress ? (
        <a
          href="#"
          className="text-blueGray-500 block"
          onClick={(e) => {  e.preventDefault(); web3Disconnect(); return false; }}
        >
          <div className="items-center flex">
            <span
              className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center
          rounded-full"
            >
              {abbreviateAddress(walletAddress)}
            </span>
          </div>
        </a>
      ) : (
        <a
          href="#"
          className={classNames("ml-8 inline-flex items-center justify-center whitespace-nowrap", primaryButtonClass)}
          onClick={(e) => { e.preventDefault(); web3Connect(); return false; }}
        >
          Connect
        </a>
      )}*/}

      <a
        className="relative text-blueGray-500 block"
        href="#"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="text-[#1C302B] mr-2 hidden lg:inline">
            {abbreviateAddress(walletAddress)}
          </span>
          <span
            className="w-12 h-12 text-sm text-[#1C302B] bg-blueGray-200 inline-flex items-center justify-center
          rounded-full"
          >
            {/*<Davatar*/}
            {/*  // className="w-full rounded-full align-middle border-none shadow-lg"*/}
            {/*  size={48}*/}
            {/*  address={walletAddress}*/}
            {/*  provider={provider} // optional*/}
            {/*  // graphApiKey={apiKey} // optional*/}
            {/*  generatedAvatarType='blockies' // optional, 'jazzicon' or 'blockies'*/}
            {/*/>*/}

            <i className="fas fa-user text-xl text-blueGray-800"></i>
          </span>
        </div>
      </a>
      <div
        ref={dropdownMenuRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 " +
          "absolute top-20 -right-2 lg:right-16"
        }
      >
        <span
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          <i className="fas fa-wallet mr-1"></i>&nbsp;
          <span className="hidden md:inline">{walletAddress}</span>
          <span className="md:hidden">{abbreviateAddress(walletAddress)}</span>
        </span>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {  e.preventDefault(); web3Disconnect(); return false; }}
        >
          <i className="fas fa-sign-out-alt mr-1"></i> Disconnect
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
