/* eslint-disable no-unused-vars */
import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "pages/Mobile/Positions/[token]/Components/DurationSelector";
import { primaryButtonClass } from "pages/Mobile/Earn";

export default function ConfirmTermsModal(
  { open, setOpen, onConfirmTerms }: { open: boolean; setOpen: (open: boolean) => void; onConfirmTerms: () => void; }
) {
  const [acceptTerms, setAcceptTerms] = React.useState<boolean>(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-2 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-black w-full
                lg:w-1/2 shadow-xl transition-all"
              >
                <div className="mt-3 mb-4 mx-5 text-white">
                  <div className="text-justify">
                    <h1 className="text-xl font-bold mb-3 text-center">Terms and conditions</h1>
                    <p className="mb-2">Investing in Digital Assets carries risk and it’s important to remember you are
                      responsible for the safekeeping of your wallet, seed phrase and private keys.</p>
                    <p className="mb-2">Digital Assets are not bank deposits, are not legal tender, are not backed
                      by the government and no government protections apply.</p>
                    <p className="mb-2">Blum Finance does not provide financial, investment, tax, or legal advice.
                      No communication or content provided by us is intended to imply financial advice, nor that
                      any Digital Asset or strategy to generate rewards is low-risk.</p>
                    <p>By using our website and services you agree to our Terms of Service and Privacy Policy.</p>
                  </div>
                  <div className="mt-5 flex justify-center items-center">
                    <input
                      className="form-check-input h-6 w-6 border border-gray-300 rounded-sm bg-white
                      checked:bg-emerald-500 border-emerald-500 focus:outline-none transition duration-200 mt-1
                      align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer text-white
                      accent-emerald-500"
                      type="checkbox"
                      value=""
                      id="acceptStakeTerms"
                      checked={acceptTerms}
                      onChange={(e) => setAcceptTerms(e.target.checked)}
                    />
                    <label
                      className="inline-block text-white form-check-label"
                      htmlFor="acceptStakeTerms"
                    >
                      I have read and agree to the
                      <a
                        href="https://blum.finance/terms"
                        target="_blank"
                        className="text-[#007D8C] text-right" rel="noreferrer"
                      > terms of service
                      </a> & <a
                        href="https://blum.finance/privacy"
                        target="_blank"
                        className="text-[#007D8C] text-right" rel="noreferrer"
                      > privacy policy
                      </a>
                    </label>
                  </div>
                  <div className="mt-4">
                    <div className="inline-block">
                      <button
                        className={classNames("w-full", primaryButtonClass)}
                        onClick={() => {
                          if (!acceptTerms) {
                            alert("Please accept the terms and conditions before using this service.");
                            return;
                          }

                          onConfirmTerms();
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
