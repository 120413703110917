import TokenSymbol from "components/TokenSymbol";
import React from "react";
import { PositionType } from "../../../types/Position";
import Link from "components/Link";
import moment from "moment/moment";

function CurrentStakeRow({position}: {position: PositionType}) {
  const creationDate = moment(position.created, "X");
  const maturityDate = moment(position.created + position.lockTime, "X");
  const progressPercentage = Math.min(100, Math.floor((moment().unix() - creationDate.unix()) /
    (maturityDate.unix() - creationDate.unix()) * 100));
  const earnedAmount = position.amount * position.apr / 100 * progressPercentage / 100;
  const dollarPrice = position.dollarValue / position.amount;
  const earnedDollarValue = earnedAmount * dollarPrice;

  return (
    <tr className="hover:bg-gray-100">
      <td>
        <Link
          to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId}
          className="mt-2 mb-3 block h-full w-full flex items-center"
        >
          <TokenSymbol address={position.token.address} className="inline w-12 h-12" />
          <div className="ml-2">
            <div className="text-sm font-semibold text-gray-500">{position.token.longName}</div>
            <div className="font-semibold">{position.token.shortName}</div>
          </div>
        </Link>
      </td>
      <td>
        <Link
          to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId}
          className="block h-full w-full"
        >
          {position.amount.toFixed(4)} {position.token.shortName} (${position.dollarValue.toFixed(2)})
        </Link>
      </td>
      <td>
        <Link
          to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId}
          className="block h-full w-full"
        >
          {position.apr}%
        </Link>
      </td>
      <td>
        <Link
          to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId}
          className="block h-full w-full"
        >
          {earnedAmount.toFixed(4)} {position.token.shortName} (${earnedDollarValue.toFixed(2)})
        </Link>
      </td>
      <td>
        <Link
          to={"/#/mobile/positions/" + position.token.address + "/" + position.tokenId}
          className="block h-full w-full"
        >
          {maturityDate.format("lll")}
          {progressPercentage >= 100 && (
            <div className="text-xs text-gray-500">Matured</div>
          )}
        </Link>
      </td>
    </tr>
  );
}

export default CurrentStakeRow;
