import React from "react";
import { Web3BombAppProvider } from "context/Web3Bombapp";
import { RefreshContextProvider } from "context/RefreshContext";
import { BeefyContextProvider } from "context/BeefyContext";
import { BombApiContextProvider } from "context/BombApiContext";
import { Outlet } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar";
import HeaderWelcome from "components/Headers/HeaderWelcome";
import FooterAdmin from "components/Footers/FooterAdmin";
import ConfirmTerms from "components/ConfirmTerms/ConfirmTerms";
import Geofence from "components/Geofence/Geofence";

export default function MobileLayout() {
  return (
    <RefreshContextProvider>
      <Web3BombAppProvider>
        <BombApiContextProvider>
          <BeefyContextProvider>
            <div className="Mobile">
              <>
                <Geofence>
                  <ConfirmTerms />
                </Geofence>
                <Sidebar />
                <div className="relative md:ml-64 bg-[#FFFAF5]">
                  <AdminNavbar />
                  <HeaderWelcome />
                  <div className="px-4 md:px-14 mx-auto w-full">
                    <Outlet />
                    <FooterAdmin />
                  </div>
                </div>
              </>
            </div>
          </BeefyContextProvider>
        </BombApiContextProvider>
      </Web3BombAppProvider>
    </RefreshContextProvider>
  );
}
