import React from "react";
import { useRouteError } from "react-router-dom";
import logo from "assets/images/blumfinance_logo.png";
import Link from "components/Link";
import { primaryButtonClass } from "pages/Mobile/Earn";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page" style={{
        backgroundColor: "#033246", 
        height: "100%", 
        width: "100%", 
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={logo} alt="Logo" className="mb-10 mx-auto w-128" />
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {/* @ts-ignore */}
        <i>{error.statusText || error.message}</i>
      </p>
      <div className="mt-10">
        <Link to="/" className={primaryButtonClass}>Go to Home</Link>
      </div>
    </div>
  );
}
