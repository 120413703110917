import React from "react";
import { BombApiContext } from "context/BombApiContext";

export default function HeaderWelcome() {
  const { userInfo } = React.useContext(BombApiContext);

  if (!userInfo) {
    return;
  }

  return (
    <>
      <div className="relative bg-[#FFFAF5] md:pt-24 pb-12 pt-12">
        <div className="px-4 md:px-14 mx-auto w-full">
          <div className="flex items-center">
            <span
              className="w-14 h-14 text-sm text-[#1C302B] bg-[#033246] inline-flex items-center justify-center
               rounded-full mr-3"
            >
              <i className="fas fa-user text-xl text-white"></i>
            </span>
            <div>
              <div className="text-xl font-bold">Welcome back{userInfo.name ? ", " + userInfo.name : ""}!</div>
              {/*<div className="text-[#007D8C] font-extrabold">Complete your profile to earn massive rewards!</div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
