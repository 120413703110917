import React from "react";
import { Link as WrappedLink } from "react-router-dom";

export const Link = (props: any) => {
  const passedProps = {
    ...props,
  };

  if (props.external) {
    delete passedProps.external;
    return <a href={props.to} {...passedProps} target="_blank" rel="noreferrer">{props.children}</a>;
  }
  delete passedProps.external;

  // @ts-ignore
  if (window.bombRouterType !== "hash" && props.to) {
    passedProps.to = props.to.replace("/#/mobile", "");

    return <WrappedLink {...passedProps} />;
  }

  if (props.to) {
    passedProps.to = props.to.replace("/#/", "/");
  }

  return <WrappedLink {...passedProps} />;
};

export default Link;
