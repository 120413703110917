import React, { useEffect } from "react";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { getStakeAssets, StakeAsset } from "context/BombApiContext";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface AssetSelectorProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  defaultAssetContract?: string;
}

export default function AssetSelector({ onChange, defaultAssetContract }: AssetSelectorProps) {
  const [selected, setSelected] = useState<StakeAsset|null>();
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    getStakeAssets().then((assets) => {
      const mappedAssets = assets.stakeAssets.map((asset, index) => {
        return { ...asset, index };
      });
      setAssets(mappedAssets);
      setSelected(null);
      if (defaultAssetContract) {
        for (const asset of mappedAssets) {
          if (asset.assetContract === defaultAssetContract) {
            setSelected(asset);
            break;
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  if (assets.length === 0) {
    return null;
  }

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className={classNames(assets.length > 4 ? "lg:flex lg:space-x-4" : "flex space-x-4")}>
        {assets.map((asset, index) => (
          <RadioGroup.Option
            key={"asset_" + index}
            value={asset}
            className={({ checked, active }) =>
              classNames(
                active || checked ?
                  "bg-[#007D8C] text-white" :
                  "bg-white text-emerald-500",
                "relative block cursor-pointer rounded-lg p-3 focus:outline-none justify-between grow " +
                "bg-transparent border border-solid border-[#edf6fa]"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    active || checked ? "text-white" : "bg-white text-[#007D8C]",
                    "flex flex-col text-sm text-center w-full")}
                >
                  <RadioGroup.Label as="span" className="font-medium">
                    {(asset.assetSymbol)}
                  </RadioGroup.Label>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
