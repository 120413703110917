import Link from "components/Link";
import React from "react";
import { PositionType } from "../../../types/Position";
import CurrentStakeRow from "components/Home/CurrentStakeRow";

function CurrentStakes(
  {positions}:
    {
      positions: PositionType[];
    }
) {
  return (
    <div className="p-4">
      <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>Current stakes</h1>
      {positions.length > 0 && (
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left align-top text-gray-300 text-xs">Coin</th>
              <th className="text-left align-top text-gray-300 text-xs">Staked</th>
              <th className="text-left align-top text-gray-300 text-xs">APR</th>
              <th className="text-left align-top text-gray-300 text-xs">Earned</th>
              <th className="text-left align-top text-gray-300 text-xs">Maturity date</th>
            </tr>
          </thead>
          <tbody>
            {positions.map((position, index) => (
              <CurrentStakeRow position={position} key={index} />
            ))}
          </tbody>
        </table>
      )}
      {positions.length <= 0 && (
        <div className="text-justify ml-6 text-black mt-5">
          Looks like you don&apos;t have any stake positions yet. <br />
          Not to worry, <Link to={"/#/mobile/earn"} className="text-emerald-500">click here</Link> to
          create one!
        </div>
      )}
    </div>
  );
}

export default CurrentStakes;
