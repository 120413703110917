/*eslint-disable*/
import React, { useEffect } from "react";

import UserDropdown from "components/Dropdowns/UserDropdown";
import Link from "components/Link";
import { useLocation } from "react-router-dom";
import logoShort from "assets/images/blumfinance_logo.png";
import logo from "assets/images/blumfinance_logo.png";

export const menu = [
  {
    name: "Portfolio",
    children: [
      {
        name: "Overview",
        href: "/home",
        icon: "fa-tv",
      },
      {
        name: "Positions",
        href: "/positions",
        icon: "fa-tv",
        hidden: true,
      },
      {
        name: "Earn",
        href: "/earn",
        icon: "fa-funnel-dollar",
      },
      {
        name: "Refer",
        href: "/refer",
        icon: "fa-user-friends",
      },
    ]
  },
  // {
  //   name: "View Positions",
  //   href: "/staked"
  // },
  {
    name: "Account",
    children: [
      {
        name: "Buy",
        href: "/buy",
        icon: "fa-credit-card",
      },
      {
        name: "Deposit",
        href: "/deposit",
        icon: "fa-box",
      },
      {
        name: "Withdraw",
        href: "/withdraw",
        icon: "fa-arrow-up",
      }
    ]
  },
  {
    name: "Documentation",
    children: [
      {
        name: "FAQs",
        href: "https://blum.finance/faqs/",
        external: true,
        icon: "fa-question",
      },
    ]
  }
];

export default function Sidebar() {
  const location = useLocation();
  // @ts-ignore
  const prefix = window.bombRouterType === "hash" ? "/mobile" : "";
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  useEffect(() => {
    setCollapseShow("hidden");
  }, [location.pathname]);

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden
       md:shadow-xl bg-[#033246] flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6
        md:pt-0 md:px-0">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-[#033246] m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase
             font-bold p-4 px-0 md:px-6 md:pt-6 md:pb-7"
            to="/"
          >
            <div className="relative md:hidden lg:hidden">
              <img
                src={logoShort}
                alt="Blum"
                className="relative -top-1 w-32"
              />
            </div>
            <img
              src={logo}
              alt="Blum Finance"
              className="hidden md:block lg:block w-full"
            />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            {/*<li className="inline-block relative">*/}
            {/*  <NotificationDropdown />*/}
            {/*</li>*/}
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute " +
              "top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded px-6 " +
              collapseShow
            }
          >
            <Link to="/refer" className="text-white bg-[#03A887] inline-block py-1 px-2 mb-3">
              Refer a friend to earn $25
            </Link>
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Blum Finance
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times" style={{color: "#ffffff",}} ></i>
                  </button>
                </div>
              </div>
            </div>

            {menu.map((item, index) => (
              <div key={index}>
              {/* Heading */}
                <h6 className="md:min-w-full text-white lg:text-white text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  {item.name}
                </h6>
                {/* Navigation */}

                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  {/* @ts-ignore */}
                  {item.children.map((child, index2) => !child.hidden && (
                    <li className="items-center" key={index + "_" + index2}>
                      <Link
                        // @ts-ignore
                        external={child.external ? child.external : false}
                        className={
                          "text-xs uppercase py-3 font-bold block " +
                          (location.pathname.indexOf(child.href) !== -1
                            ? "text-[#079D82] hover:text-[#079D82]"
                            : "text-white lg:text-white hover:text-[#079D82]")
                        }
                        to={prefix + child.href}
                      >
                        <i
                          className={
                            "fas mr-2 text-sm " + child.icon + " " +
                            (location.pathname.indexOf(child.href) !== -1
                              ? "opacity-75"
                              : "text-blueGray-300")
                          }
                        ></i>{" "}
                        {child.name}
                      </Link>
                    </li>
                  ))}
                </ul>

                {/* Divider */}
                <hr className="border-[#41545B] my-4 md:min-w-full" />
              </div>
            ))}
          </div>
        </div>
      </nav>
    </>
  );
}
