import React from "react";

import Home from "./pages/Home";
import ErrorPage from "./ErrorPage";
import Earn from "./pages/Mobile/Earn";
import New from "./pages/Mobile/Positions/[token]/New";
import Show from "./pages/Mobile/Positions/[token]/Show";
import Redeem from "./pages/Mobile/Positions/[token]/Redeem";
import Refund from "./pages/Mobile/Positions/[token]/Refund";
import Compound from "./pages/Mobile/Positions/[token]/Compound";
import Deposit from "./pages/Mobile/Deposit";
import Withdraw from "./pages/Mobile/Withdraw";
import DepositCoin from "./pages/Mobile/Positions/[token]/DepositCoin";
import WithdrawCoin from "./pages/Mobile/Positions/[token]/WithdrawCoin";
import Buy from "./pages/Mobile/Positions/[token]/Buy";
import Refer from "./pages/Mobile/Refer";
import ReferredBy from "./pages/Mobile/Refer/[code]";
import SelectBonusAsset from "pages/SelectBonusAsset";

export default [
  {
    path: "",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "home",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "earn",
    element: <Earn />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/new",
    element: <New />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/:tokenId",
    element: <Show />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/:tokenId/redeem",
    element: <Redeem />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/:tokenId/refund",
    element: <Refund />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/:tokenId/compound",
    element: <Compound />,
    errorElement: <ErrorPage />,
  },
  {
    path: "deposit",
    element: <Deposit />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/deposit",
    element: <DepositCoin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "withdraw",
    element: <Withdraw />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/withdraw",
    element: <WithdrawCoin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "buy",
    element: <Buy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "buy/:address",
    element: <Buy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "positions/:address/buy",
    element: <Buy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "refer",
    element: <Refer />,
    errorElement: <ErrorPage />,
  },
  {
    path: "refer/:code",
    element: <ReferredBy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "select-bonus",
    element: <SelectBonusAsset />,
    errorElement: <ErrorPage />,
  },
];
