import React, { useEffect } from "react";
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import Web3 from "web3";
import STAKEPOSITION from "context/abi/STAKEPOSITION.json";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface DurationSelectorProps {
  stakeContract: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  // eslint-disable-next-line no-unused-vars
  render?: (duration: any) => React.ReactNode;
  defaultValueIndex?: number;
}

// We have to use a different provider here because this component may be rendered in places that don't have access to
// BOMBAppWeb3Context
const getContract = (abi: any, address: string) => {
  const readOnlyProvider = new Web3("https://rpc.bombchain.com/");
  return new readOnlyProvider.eth.Contract(abi, address);
};
const getAllLockOptions = async (stakeContract: string): Promise<any[]> => {
  const contract = getContract(STAKEPOSITION, stakeContract);
  return contract.methods.getAllLockOptions().call();
};

export default function DurationSelector(
  { stakeContract, onChange, defaultValueIndex = -1, render }: DurationSelectorProps
) {
  const [selected, setSelected] = useState();
  const [durations, setDurations] = useState([]);

  useEffect(() => {
    getAllLockOptions(stakeContract).then((lockOptions: any[]) => {
      const mappedLockOptions = lockOptions.map((lockOption, index) => {
        return { ...lockOption, index };
      });
      setDurations(mappedLockOptions);
      setSelected(mappedLockOptions[defaultValueIndex !== -1 ? defaultValueIndex : mappedLockOptions.length - 1]);
    });
  }, []);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  if (durations.length === 0) {
    return null;
  }

  if (!render) {
    render = (duration: any) => {
      return (
        <RadioGroup.Option
          value={duration}
          className={({ checked, active }) =>
            classNames(
              active || checked ?
                "bg-[#3d7b95] text-white" :
                "bg-white text-emerald-500",
              "relative block cursor-pointer rounded-lg p-3 focus:outline-none justify-between grow " +
              "bg-transparent border border-solid border-[#3d7b95]"
            )
          }
        >
          {({ active, checked }) => (
            <>
              <span
                className={classNames(
                  active || checked ? "text-white" : "bg-white text-[#3d7b95]",
                  "flex flex-col text-sm text-center w-full")}
              >
                <RadioGroup.Label as="span" className="font-medium">
                  {(duration[1] / 86400).toFixed(0) + " days"}
                </RadioGroup.Label>
              </span>
            </>
          )}
        </RadioGroup.Option>
      );
    };
  }

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only"> Duration </RadioGroup.Label>
      <div className={classNames(durations.length > 3 ? "lg:flex lg:space-x-4" : "flex space-x-4")}>
        {durations.map((duration) => (
          <React.Fragment key={duration[1]}>
            {render(duration)}
          </React.Fragment>
        ))}
      </div>
    </RadioGroup>
  );
}
