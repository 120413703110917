import React from "react";
import { classNames } from "pages/Mobile/Positions/[token]/Components/DurationSelector";

export default function FooterAuth({layout = "centered"}: {layout?: string}) {
  return (
    <>
      <footer
        className={classNames(
          "absolute w-full bottom-0 pb-6", layout !== "split" ? "lg:bg-[#033246]" : ""
        )}
      >
        <div className="container px-4 mx-auto">
          <hr className="mb-6 border-b-1 border-blueGray-600" />
          <div className="flex flex-wrap items-center justify-center md:justify-between">
            <div className="w-full px-4 md:w-4/12">
              <div
                className={classNames(
                  "py-1 text-sm font-semibold text-center md:text-left",
                  layout !== "split" ? "text-blueGray-500" : "text-[#033246]"
                )}
              >
                Copyright © {new Date().getFullYear()}{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://blum.finance"
                  className={classNames(
                    "py-1 text-sm font-semibold",
                    layout !== "split" ? "text-white hover:text-blueGray-300" : "text-[#033246]"
                  )}
                >
                  Blum Finance
                </a>
              </div>
            </div>
            <div className="w-full px-4 md:w-8/12">
              <ul className="flex flex-wrap justify-center list-none md:justify-end">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://blum.finance/terms/"
                    className={classNames(
                      "block px-3 py-1 text-sm font-semibold",
                      layout !== "split" ? "text-white hover:text-blueGray-300" : "text-[#033246]"
                    )}
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://blum.finance/privacy/"
                    className={classNames(
                      "block px-3 py-1 text-sm font-semibold",
                      layout !== "split" ? "text-white hover:text-blueGray-300" : "text-[#033246]"
                    )}
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://blum.finance/faqs/"
                    className={classNames(
                      "block px-3 py-1 text-sm font-semibold",
                      layout !== "split" ? "text-white hover:text-blueGray-300" : "text-[#033246]"
                    )}
                  >
                    About Blum Finance
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://blum.finance/blog/"
                    className={classNames(
                      "block px-3 py-1 text-sm font-semibold",
                      layout !== "split" ? "text-white hover:text-blueGray-300" : "text-[#033246]"
                    )}
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
