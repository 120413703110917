import React, { createContext, useEffect, useState } from "react";
import Web3 from "web3";
import Web3Modal, { IProviderOptions } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import ERC20ABI from "./abi/ERC20.json";
import STAKEPOSITION from "./abi/STAKEPOSITION.json";
import STAKEVAULT from "./abi/STAKEVAULT.json";
import BOMBCHAINBRIDGE from "./abi/BOMBCHAINBRIDGE.json";
import ConnectWallet from "../pages/ConnectWallet";
import SwitchChain from "../pages/SwitchChain";
import { useLocation } from "react-router-dom";
import Offline from "pages/Offline";
import SelectBonusAsset from "pages/SelectBonusAsset";

export const Web3BombappContext = createContext(null);

const bombChainMainnetId = 2300;

export const InjectedProviderExists = () => {
  if (typeof window.ethereum !== "undefined") {
    return true;
  } else if (window.web3) {
    return true;
  } else if (window.celo) {
    return true;
  }

  return false;
};

export function Web3BombAppProvider(props: any) {
  const decimals = 18;

  const location = useLocation();
  const [provider, setProvider] = useState<any>(null);
  const [web3Modal, setWeb3Modal] = useState<Web3Modal>(null);
  const [currentChainId, setCurrentChainId] = useState(bombChainMainnetId);
  const [web3Connecting, setWeb3Connecting] = useState(false);
  const [web3Connection, setWeb3Connection] = useState<Web3>();
  const [web3Connected, setWeb3Connected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [offline, setOffline] = React.useState(false);

  useEffect(() => {
    const providerOptions: IProviderOptions = {
      torus: {
        package: Torus,
        display: {
          description: "Connect using Google, Facebook, Twitter, Github, or email",
        },
        options: {
          networkParams: {
            host: "https://rpc.bombchain.com",
            chainId: 2300,
            networkId: 2300,
            networkName: "BOMB Chain",
            blockExplorer: "https://www.bombscan.com",
            ticker: "BOMB",
            tickerName: "BOMB",
          },
          // All options can be found here:
          // https://github.com/WalletConnect/web3modal/blob/V1/src/providers/connectors/torus.ts
          config: {
            showTorusButton: true,
            buildEnv: "production",
            whiteLabel: {
              logoDark: "https://raw.githubusercontent.com/bombmoney/bomb-assets/master/Wallet%20light.png",
              logoLight: "https://raw.githubusercontent.com/bombmoney/bomb-assets/master/Wallet%20light.png",
              topupHide: true,
              privacyPolicy: "https://blum.finance/privacy",
              tncLink: "https://blum.finance/terms/"
            }
          }
        },
      },
      injected: {
        package: null,
        display: {
          name: "Injected",
          description: "Metamask",
        },
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            2300: "https://rpc.bombchain.com",
          },
        },
      },
    };

    const newWeb3Modal = new Web3Modal({
      cacheProvider: true, // This ensures that the user is auto-connected
      // network: "bombchain",
      providerOptions,
    });

    setWeb3Modal(newWeb3Modal);

    const onSelect = () => {
      setWeb3Connecting(true);
    };
    newWeb3Modal.on("select", onSelect);
    return () => {
      newWeb3Modal.off("select", onSelect);
    };
  }, []);

  useEffect(() => {
    // connect automatically and without a popup if the user ios viewing the app pages
    // @ts-ignore
    if (web3Modal && window.bombRouterType === "hash") {
      (async () => {
        const provider = await web3Modal.connectTo("injected");
        setProvider(provider);
      })();
      return;
    }

    // connect automatically and without a popup if user is already connected
    if (web3Modal && web3Modal.cachedProvider) {
      (async () => {
        if (web3Modal.cachedProvider === "torus") {
          // Check if the user is connected to torus and only then auto-login
        } else {
          web3Connect();
        }
      })();
    }
  }, [web3Modal]);

  const web3Connect = async (connector?: string) => {
    try {
      let provider;
      if (connector) {
        provider = await web3Modal.connectTo(connector);
      } else {
        provider = await web3Modal.connect();
      }
      setProvider(provider);
      setWeb3Connecting(false);
    } catch (e) {
      await web3Modal.clearCachedProvider();
      setWeb3Connecting(false);
      throw e;
    }
  };

  const web3Disconnect = async () => {
    if (provider && provider.torus) {
      await provider.torus.cleanUp();
    }

    await web3Modal.clearCachedProvider();
    window.location.reload();
  };

  const switchToBombChain = async () => {
    if (!window.ethereum) {
      alert("Automatically switching networks is not supported by your browser. Please switch to BOMB Chain manually.");
      return;
    }

    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x8FC",
        rpcUrls: ["https://rpc.bombchain.com"],
        chainName: "BOMB Chain",
        nativeCurrency: {
          name: "BOMB",
          symbol: "BOMB",
          decimals: 18
        },
        blockExplorerUrls: ["https://www.bombscan.com/"]
      }]
    });
  };

  useEffect(() => {
    if (!provider) {
      return;
    }

    const onConnect = async () => {
      setWeb3Connection(null);
      setWeb3Connected(false);
      setWalletAddress(null);
      setCurrentChainId(null);

      const web3Connection = new Web3(provider);
      console.log("Connecting wallet"); // TODO REMOVE
      web3Connection.eth.getAccounts().then((accounts: any) => {
        console.log("Connected wallet. Account: ", accounts[0]); // TODO REMOVE
        web3Connection.eth.getChainId().then(function (chainId) {
          if (String(chainId).indexOf("0x") === 0) {
            chainId = parseInt(String(chainId), 16);
          }
          console.log("Found chainId: ", chainId); // TODO REMOVE
          setWeb3Connection(web3Connection);
          setWeb3Connected(true);
          setWalletAddress(accounts[0]);
          setCurrentChainId(Number(chainId));

          provider.on("disconnect", () => {
            window.location.reload();
          });

          provider.on("accountsChanged", (accounts: string[]) => {
            if (accounts.length <= 0 || accounts[0] !== walletAddress) {
              window.location.reload();
            }
          });
          provider.on("chainChanged", (newChainId: any) => {
            if (String(newChainId).indexOf("0x") === 0) {
              newChainId = parseInt(String(newChainId), 16);
            }

            if (Number(currentChainId) !== Number(newChainId)) {
              window.location.reload();
            }
            console.log("Chain changed: ", chainId); // TODO REMOVE
            setCurrentChainId(Number(newChainId));
          });
        });
      });
    };
    onConnect();
    provider.on("connect", onConnect);

    return () => {
      provider.removeAllListeners("connect");
      provider.removeAllListeners("disconnect");
      provider.removeAllListeners("accountsChanged");
      provider.removeAllListeners("chainChanged");
    };
  }, [provider]);

  React.useEffect(() => {
    const onlineListener = () => {
      setOffline(false);
    };
    const offlineListener = () => {
      setOffline(true);
    };

    window.addEventListener("online", onlineListener);
    window.addEventListener("offline", offlineListener);
    return () => {
      window.removeEventListener("online", onlineListener);
      window.removeEventListener("offline", offlineListener);
    };
  }, []);

  const getContract = (abi: any, address: string) => {
    if (!address) {
      throw new Error("No contract address provided");
    }
    return new web3Connection.eth.Contract(abi, address);
  };

  const toPlainString = (num: any) => {
    return ("" + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join("0") + c + d
          : b + c + d + Array(e - d.length + 1).join("0");
      });
  };

  if (offline) {
    return (
      <Offline />
    );
  }

  const isSelectBonus = location.pathname.startsWith("/select-bonus");
  if (isSelectBonus) {
    return (
      <SelectBonusAsset />
    );
  }

  const isRefferalLink = location.pathname.startsWith("/refer/") && location.pathname !== "/refer/";
  if (!isRefferalLink) { // Allow the referral page to be loaded even if there is no wallet connected
    if (currentChainId !== bombChainMainnetId) {
      return (
        <SwitchChain onClick={switchToBombChain} onDisconnect={web3Disconnect} />
      );
    }

    if (!web3Connection || !web3Connected || walletAddress === "" || !walletAddress) {
      return (
        <ConnectWallet web3Connect={web3Connect} web3Connecting={web3Connecting} web3Disconnect={web3Disconnect} />
      );
    }
  }

  return (
    <Web3BombappContext.Provider
      value={{
        web3Connect,
        web3Disconnect,
        web3Connecting,
        web3Connection,
        web3Connected,
        walletAddress,
        provider,
        getBalance: async (contractAddress: string) => {
          const contract = getContract(ERC20ABI, contractAddress);
          const approvalAmount = await contract.methods.balanceOf(walletAddress).call();

          return approvalAmount / Math.pow(10, decimals);
        },
        getAllowance: async (contractAddress: string, spender: string) => {
          const contract = getContract(ERC20ABI, contractAddress);
          const approvalAmount = await contract.methods.allowance(walletAddress, spender).call();

          return approvalAmount / Math.pow(10, decimals);
        },
        approveContract: (
          contractAddress: string,
          spender: string,
          amount: string = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        ) => {
          const contract = getContract(ERC20ABI, contractAddress);
          return contract.methods.approve(spender, amount).send({ from: walletAddress });
        },
        hasReceivedBonus: async (
          contractAddress: string,
          userAddress: string
        ) => {
          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.hasReceivedBonus(userAddress).call();
        },
        getAllLockOptions: (contractAddress: string) => {
          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.getAllLockOptions().call();
        },
        getReferralMinAmount: async (contractAddress: string) => {
          const contract = getContract(STAKEPOSITION, contractAddress);
          return await contract.methods.referralMinAmount().call() / Math.pow(10, decimals);
        },
        getUserBonus: async (userAddress: string) => {
          const contract = getContract(STAKEVAULT, "0x167441fF9fe49dC5cC316426e960f8336D0639dF");
          return await contract.methods.usersBonus(userAddress).call();
        },
        createStake: (contractAddress: string, amount: number, lockOptIndex: number) => {
          const adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));

          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.stake(adjustedAmount, lockOptIndex).send({ from: walletAddress });
        },
        createStakeWithReferral: (
          contractAddress: string,
          amount: number,
          lockOptIndex: number,
          referrerAddress: string) => {
          const adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));

          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.stakeWithReferral(
            adjustedAmount,
            lockOptIndex,
            referrerAddress
          ).send({ from: walletAddress });
        },
        createFreeStake: (contractAddress: string, amount: number) => {
          const adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));

          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.initialBonus(adjustedAmount).send({ from: walletAddress });
        },
        withdrawAsset(address: string, amount: number, account: string, destinationChainId: number | string) {
          const adjustedAmount = toPlainString(Math.floor(Math.abs(amount * Math.pow(10, 18))));

          const contractAddress = "0x2a06800f3F935024d327D6C632Ca000f00B9CFEd"; // Bridge contract address
          const contract = getContract(BOMBCHAINBRIDGE, contractAddress);
          return contract.methods.bridgeAsset(
            address,
            adjustedAmount,
            account,
            String(destinationChainId)
          ).send({ from: walletAddress });
        },
        getAllUserOwned: (contractAddress: string) => {
          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.getAllUserOwned(walletAddress).call();
        },
        stakeInfo: (contractAddress: string, tokenId: number) => {
          const contract = getContract(STAKEPOSITION, contractAddress);
          // Intentionally don't await here since we want to fetch them in parallel
          return contract.methods.stakes(tokenId).call();
        },
        claimAndCompound: (
          contractAddress: string,
          walletAddress: string,
          tokenId: number,
          lockOptIndex: number
        ) => {
          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.claimAndCompound(walletAddress, tokenId, lockOptIndex).send({ from: walletAddress });
        },
        withdraw: (contractAddress: string, tokenId: number) => {
          const contract = getContract(STAKEPOSITION, contractAddress);
          return contract.methods.withdraw(tokenId, true).send({ from: walletAddress });
        },
        freeEarlyWithdraw: (contractAddress: string, tokenId: number) => {
          const spender = "0x167441fF9fe49dC5cC316426e960f8336D0639dF";
          const contract = getContract(STAKEVAULT, spender);
          return contract.methods.freeEarlyWithdraw(contractAddress, tokenId).send({ from: walletAddress });
        },
      }}
    >
      {props.children}
    </Web3BombappContext.Provider>
  );
}

/**
 * Check if the transaction was success based on the receipt.
 *
 * https://ethereum.stackexchange.com/a/45967/620
 *
 * @param receipt Transaction receipt
 */
export function isSuccessfulTransaction(receipt: any): boolean {
  return receipt.status == "0x1" || receipt.status == 1;
}
