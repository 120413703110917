import React, { useEffect } from "react";
import AuthLayout from "components/AuthLayout";
import AssetSelector from "components/SelectBonusAsset/AssetSelector";
import { primaryButtonClass, tokens } from "pages/Mobile/Earn";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "context/BombApiContext";
import Web3 from "web3";

function SelectBonusAsset() {
  const [amount, setAmount] = React.useState(100);
  const [amountInCoin, setAmountInCoin] = React.useState(0);
  const [selectedAsset, setSelectedAsset] = React.useState(null);
  const navigate = useNavigate();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (selectedAsset) {
      fetch("https://api.bomb.farm/prices")
        .then((response) => response.json())
        .then((data) => {
          setAmountInCoin(
            Number(amount) / Number(data[tokens[Web3.utils.toChecksumAddress(selectedAsset.assetContract)].beefySymbol])
          );
        });
    }
  }, [amount, selectedAsset]);

  const userId = searchParams.get("userId");
  if (userId == null) {
    return (
      <AuthLayout>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div
                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200
            border-0 z-10"
              >
                <div className="text-center my-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Error: User id not provided
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }


  return (
    <AuthLayout>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <h1 className="relative z-10 text-white text-2xl font-medium text-center">
              Thank you for providing your email address!
            </h1>
            <h2 className="relative z-10 text-white text-lg text-center mb-10">
              You&apos;re just a couple of steps away from
              securing your bonus of $1000!
            </h2>

            <div
              className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200
            border-0 z-10"
            >
              <div className="rounded-t mb-0 px-6 py-3">
                <div className="relative pb-4">
                  <label
                    htmlFor="select-bonus-asset-amount"
                    className="mt-1 text-center block mb-2 text-sm text-blueGray-500 font-bold"
                  >Choose your staking bonus below!</label>
                  <div className="mt-5 mb-5">
                    <AssetSelector onChange={(asset) => { setSelectedAsset(asset); }} />
                  </div>
                  <input
                    id="select-bonus-asset-amount"
                    type="range"
                    min="10"
                    max="1000"
                    value={amount}
                    onChange={(e) => setAmount(parseInt(e.target.value))}
                    className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-[#007D8C]"
                  />
                  <div className="absolute left-0 bottom-0 text-xs text-blueGray-500">$10</div>
                  <div className="absolute right-0 bottom-0 text-xs text-blueGray-500">$1000</div>
                  {(amount && amount > 0) ? (
                    <div className="absolute font-bold w-full text-center mt-1 text-sm text-blueGray-500">
                      ${amount}
                    </div>
                  ) : (<></>)}
                </div>
                {amount > 0 && (
                  <div>
                    {selectedAsset && (
                      <>
                        <div className="mt-5 text-center text-blueGray-500 text-sm font-bold">
                          Check out our amazing rates for {selectedAsset.assetSymbol}&nbsp;
                          <a
                            href="https://blum.finance/rates/"
                            className="text-[#007D8C]"
                            target="_blank"
                            rel="noreferrer"
                          >here</a>.
                        </div>
                        <div className="grid grid-cols-2 gap-5">
                          <div
                            className="text-center bg-white border border-black mt-5
                              text-blueGray-500 text-sm p-3 font-medium"
                          >
                            <div>Free Stake:</div>
                            <div className="text-[#007D8C]">
                              {amountInCoin.toFixed(4)} {selectedAsset.assetSymbol} / ${amount}
                            </div>
                            {/*  Claim your free staking position of ${amount} /!*{*/}
                            {/*  selectedAsset && amountInCoin &&*/}
                            {/*(<>({amountInCoin.toFixed(4)} {selectedAsset.assetSymbol})</>)*/}
                            {/*} *!/ and start earning rewards instantly!*/}
                          </div>
                          <div
                            className="text-center bg-white border border-black mt-5
                              text-blueGray-500 text-sm p-3 font-medium"
                          >
                            <div>Claim Requirements</div>
                            <div className="text-[#007D8C]">
                              Stake {(amountInCoin * 10).toFixed(4)} {selectedAsset.assetSymbol} in 90 days
                            </div>
                            {/*<div>*/}
                            {/*  Then you will have 90 days to stake ${amount * 10} /!* {*/}
                            {/* selectedAsset && amountInCoin &&*/}
                            {/*(<>{(amountInCoin * 10).toFixed(4)} {selectedAsset.assetSymbol}</>)*/}
                            {/*} (${amount * 10} at current value) *!/*/}
                            {/*  in order to claim your bonus, withdrawable in 180 days.*/}
                            {/*</div>*/}
                          </div>
                        </div>

                        <div className="mt-5 flex justify-center">
                          <button
                            className={primaryButtonClass}
                            onClick={() => {
                              (async() => {
                                try {
                                  await fetch(`${baseUrl}/user`,
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        uid: searchParams.get("userId"),
                                        depositBonusAsset: selectedAsset.assetContract,
                                        depositBonusAmount: amount,
                                      }),
                                    }).then((response) => response.json()).then((data) => {
                                    searchParams.set("userId", data.user.referralCode);
                                    return data;
                                  });
                                } catch (e) {
                                  alert("Error setting the deposit bonus asset");
                                  throw e;
                                }

                                searchParams.set("amount", String(amount));
                                searchParams.set("asset", selectedAsset.assetSymbol);
                                searchParams.set("userId", userId);
                                navigate({
                                  pathname: "/",
                                  search: searchParams.toString(),
                                });
                              })();
                            }}
                          >
                            Confirm and start earning
                          </button>
                        </div>

                        <div className="mt-3 text-xs text-center">Subject to&nbsp;
                          <a
                            href="https://blum.finance/terms/"
                            target="_blank"
                            className="text-[#007D8C]"
                            rel="noreferrer"
                          >
                            terms & conditions
                          </a>.
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default SelectBonusAsset;
