import React from "react";

// function getElementText(response: any, elementName: any) {
//   return response.getElementsByTagName(elementName)[0].innerHTML;
// }

function Geofence({ children }: { children?: React.ReactNode }) {
  return <>{children}</>;
  // const [userCountry, setUserCountry] = useState(null);
  //
  // const geofencedCountries = ["US"];
  //
  // useEffect(() => {
  //   fetch("https://api.hostip.info").then(response => {
  //     return response.text();
  //   }).then(xml => {
  //     return (new window.DOMParser()).parseFromString(xml, "text/xml");
  //   }).then(xmlDoc => {
  //     const countryCode = getElementText(xmlDoc , "countryAbbrev");
  //     setUserCountry(countryCode);
  //   }).catch((data) => {
  //     console.log("Geofence request failed:", data);
  //     // alert("Geofence check request failed.");
  //   });
  // }, []);
  //
  // if (!userCountry || !geofencedCountries.includes(userCountry)) {
  //   return <>{children}</>;
  // }
  //
  // return (
  //   <GeofenceModal />
  // );
}

export default Geofence;
