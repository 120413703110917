import React, { useEffect } from "react";
import { Web3BombappContext } from "./Web3Bombapp";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

export const BombApiContext = React.createContext(null);
export const baseUrl = "https://api.bombchain.com";

export const useLocalStorage = (storageKey: string, fallbackState: any) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState
  );

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

export const getDepositAssets = async (): Promise<{ depositAssets: any[] }> => {
  return await fetch(`${baseUrl}/deposit_assets`, {
    method: "GET",
  }).then((response) => response.json());
};

export interface StakeAsset {
  assetContract: string;
  assetSymbol: string;
  description: string;
  enabled: boolean;
  id: string|number;
  name: string;
  stakeContract: string;
}
export const getStakeAssets = async (): Promise<{ stakeAssets: StakeAsset[] }> => {
  return await fetch(`${baseUrl}/stake_assets`, {
    method: "GET",
  }).then((response) => response.json());
};

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
export function BombApiContextProvider(props: any) {
  const { walletAddress } = React.useContext(Web3BombappContext);
  const [userInfo, setUserInfo] = React.useState<any>(null);
  const [referredBy, setReferredBy] = useLocalStorage("referredBy", "null");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    const getUserInfo = async (wallet: string) => {
      return await fetch(`${baseUrl}/user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address: wallet,
            uid: searchParams.get("userId"),
            referredBy: referredBy,
          }),
        }).then((response) => response.json()).then((data) => {
        searchParams.set("userId", data.user.referralCode);
        return data;
      });
    };
    getUserInfo(walletAddress).then((userInfo) => {
      setUserInfo(userInfo.user);
    });
  }, [walletAddress, referredBy]);

  return <BombApiContext.Provider
    value={{
      userInfo,
      referredBy,
      setUserInfo: (userInfo: any) => {
        setUserInfo(userInfo);
      },
      setReferredBy,
      getUserInfoByReferralCode: async (referralCode: string) => {
        return await fetch(`${baseUrl}/user/by-referral-code/${referralCode}`, {
          method: "GET",
        }).then((response) => response.json());
      },
      getDepositAsset: async (address: string) => {
        return await fetch(`${baseUrl}/deposit_asset/${address}`, {
          method: "GET",
        }).then((response) => response.json());
      },
      getDepositAddress: async (network: string, wallet: string) => {
        return await fetch(`${baseUrl}/address/${network}/${wallet}`, {
          method: "GET",
        }).then((response) => response.json());
      },
      getDepositAssets,
      getStakeAssets,
      confirmTerms: async (walletAddress: string) => {
        return await fetch(`${baseUrl}/user`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              address: walletAddress,
              confirmedTerms: moment().toISOString(),
            }),
          }).then((response) => response.json());
      }
    }}
  >
    {props.children}
  </BombApiContext.Provider>;
}
