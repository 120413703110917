import React, { useState } from "react";
import logo from "../../assets/images/share.svg";
import shareIcon from "../../assets/images/share_icon.png";
import { BombApiContext } from "context/BombApiContext";
import TermsModal from "./Positions/[token]/Components/TermsModal";
//import { notifyFailed, notifySuccess } from "components/Toast";
import Loading from "../Loading";

function Refer() {
  const { userInfo } = React.useContext(BombApiContext);

  if (!userInfo) {
    return <Loading type="Loading" />;
  }

  const [referDetailsOpen, setReferDetailsOpen] = useState(false);

  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      navigator
        .share({
          title: "Earn $25",
          text: "Earn $25 by using my referral code " + userInfo.referralCode + " on the Blum Finance app!",
          url: "https://app.blum.finance/refer/" + userInfo.referralCode
        })
        .then(() => {
          console.log("Sharing successfull");
        })              
        .catch(() => {
          console.log("Sharing failed");
        });
    } else {
      console.log("Sorry! Your browser does not support Web Share API");
    }
  };

  return (
    <div className="App">
      <TermsModal 
        open={referDetailsOpen} 
        setOpen={setReferDetailsOpen} 
        url="https://blum.finance/referral-programme/" 
      />
      <div className="relative max-w-3xl mx-auto">
        <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>Blum Finance Referral Program</h1>
        <div className="flex items-center">
          <img src={logo} className="Refer-logo w-48 h-48" alt="logo" />
          <div className="text-[#3d7b95] text-5xl font-bold mr-6">
            EARN $25
          </div>
        </div>
        <ul className="text-left list-none pl-6 text-lg mr-6">
          <li className="flex">
            <div className="text-[#03a887] font-bold pr-3">&gt;</div>
            <div>Share your referral Code</div>
          </li>
          <li className="flex">
            <div className="text-emerald-500 font-bold pr-3">&gt;</div>
            <div>When they stake $100 or more and use your code you both earn a $25 Bitcoin Stake!</div>
          </li>
        </ul>
        <a
          href="https://blum.finance/referral-programme/"
          target="_blank"
          className="block text-right text-[#03a887] mr-6 mt-3"
          rel="noreferrer"
          onClick={(e) => {
            e.preventDefault();
            setReferDetailsOpen(true);
          }}
        >
          Click to find out more &gt;
        </a>
        <div className="bg-[#3d7b95] rounded-lg mx-6 mt-6 font-bold p-2 text-white">
          <div className="mx-2 text-lg">
            Total Bonus Earned:
          </div>
          <div className="mx-2 text-3xl">
            $0
          </div>
        </div>
        <div className="mt-6 mx-6 text-lg font-bold">
          REFERRAL CODE
        </div>
        <div className="relative mx-6 mb-10">
          <input
            className="bg-white rounded-lg rounded text-sm border border-blueGray-300 text-blueGray-600 text-black
                text-s py-3 text-center w-full px-4 font-bold text-2xl"
            readOnly
            value={userInfo.referralCode}
            id="referralCode"
          />
          <img
            src={shareIcon} 
            className="w-6 h-6 m-auto absolute right-4 top-3 cursor-pointer" 
            onClick={handleShareButton}
            alt="share"
          />
        </div>
      </div>
    </div>
  );
}

export default Refer;
