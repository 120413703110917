import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

const ConnectingModal = (
  { web3Connecting, web3Disconnect }: { web3Connecting: boolean; web3Disconnect: () => void }
) => {
  return (
    <Transition.Root show={web3Connecting} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-5 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg
                bg-black w-full shadow-xl transition-all lg:w-1/2 max-w-3xl"
              >
                <div>
                  <div className="mt-3 mb-3 text-center text-white px-2 sm:mt-5">
                    <h1 className="text-lg font-bold">Connecting your wallet</h1>
                    <p className="mb-6">
                      {/* @ts-ignore */}
                      {window.bombRouterType === "browser" ?
                        "Please follow the prompts presented by your wallet." :
                        "Please wait while we connect your wallet."
                      }
                    </p>
                    {/* @ts-ignore */}
                    {window.bombRouterType === "browser" && (
                      <p className="text-xs mb-6">
                        If you do not see the prompt,&nbsp;
                        <span className="underline cursor-pointer" onClick={web3Disconnect}>click here</span>
                        &nbsp;to close this modal and retry.
                      </p>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConnectingModal;
