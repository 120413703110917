/* eslint-disable max-len */
import React from "react";

import UserDropdown from "components/Dropdowns/UserDropdown";
// import { menu } from "components/Sidebar/Sidebar";
// import { useLocation } from "react-router-dom";

// const findMenuItemName = (pathname: string) => {
//   const menuItems = [];
//   for (const item of menu) {
//     if (item.children) {
//       for (const child of item.children) {
//         menuItems.push(child);
//       }
//     }
//   }

//   const menuItem = menuItems.find((item) => pathname.includes(item.href));
//   if (!menuItem) {
//     return "Blum Finance App";
//   }
//   return menuItem.name;
// };

export default function Navbar() {
  // const location = useLocation();

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 z-10 flex items-center w-full p-4 bg-transparent md:flex-row md:flex-nowrap md:justify-start">
        <div className="flex flex-wrap items-center justify-between w-full px-4 mx-autp md:flex-nowrap md:px-10">
          &nbsp;
          {/* Brand */}
          {/* <a
            className="text-[#1C302B] text-sm uppercase hidden lg:inline-block font-semibold"
            href="#"
            onClick={(e) => e.preventDefault()}
          >
            {findMenuItemName(location.pathname)}
          </a> */}
          {/* User */}
          <ul className="flex-col items-center hidden list-none md:flex-row md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
