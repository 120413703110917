/* eslint-disable */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "pages/Mobile/Earn";
import BuyComponent from "components/Buy/Buy";
import { getDepositAssets } from "context/BombApiContext";

const SelectAsset = () => {
  const navigate = useNavigate();
  const [assets, setAssets] = React.useState<any[]>([]);

  useEffect(() => {
    getDepositAssets().then((assets) => {
      const mappedAssets = assets.depositAssets.map((asset, index) => {
        return { ...asset, index };
      }).filter((value, index, self) => {
        if (!value.blockchain.evmCompatible) {
          return false;
        }

        return self.findIndex(v => v.bombchainAssetContract === value.bombchainAssetContract) === index;
      });
      setAssets(mappedAssets);
    });
  }, []);

  return (
    <div className="App">
      <div className="relative max-w-3xl mx-auto">
        <div>
          <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>Buy Crypto</h1>
          <div className="mt-5 text-2xl text-center">
            Which Coin/Token?
          </div>
          <div className="mx-3 mt-3">
            <select
              className="block text-[#3d7b95] bg-transparent border border-solid border-[#3d7b95]
               rounded-lg p-2 text-center w-full font-bold"
              onChange={(e) => {
                // @ts-ignore
                const prefix = window.bombRouterType === "hash" ? "/mobile" : "";
                navigate(prefix + "/buy/" + e.target.value);
              }}
              defaultValue=""
            >
              <option value="" disabled>Select a coin/token</option>
              {assets.map((asset) => {
                return (
                  <option key={asset.bombchainAssetContract} value={asset.bombchainAssetContract}>{asset.name}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="mt-5">&nbsp;</div>
      </div>
    </div>
  );
}

function Buy() {
  let { address } = useParams();
  if (!address) {
    return <SelectAsset />;
  }
  const token = tokens[address];

  return (
    <div className="App">
      <div className="relative max-w-3xl mx-auto">
        <h1 className={"text-blueGray-700 text-xl font-bold text-center"}>Buy Crypto</h1>
        {/*<h2 className="font-bold text-center">Minimum purchase amount: <span className="text-lg ">{depositMinimumAmount} BTC</span></h2>*/}
        <div className="mt-5">
          <BuyComponent token={token} />
        </div>
      </div>
    </div>
  );
}

export default Buy;
