import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider
} from "react-router-dom";

import "./assets/css/index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import routes from "./routes";
import ErrorPage from "./ErrorPage";
import MobileLayout from "./components/MobileLayout";
import DesktopLayout from "./components/DesktopLayout";
import { Toaster } from "react-hot-toast";

let router;
if(window.location.hash) {
  // @ts-ignore
  window.bombRouterType = "hash";
  router = createHashRouter(
    [
      {
        path: "/",
        element: <DesktopLayout />,
        errorElement: <ErrorPage />,
        children: routes
      },
      {
        path: "/mobile",
        element: <MobileLayout />,
        errorElement: <ErrorPage />,
        children: routes
      }
    ]
  );
} else {
  // @ts-ignore
  window.bombRouterType = "browser";
  router = createBrowserRouter(
    [
      {
        path: "/",
        element: <DesktopLayout />,
        errorElement: <ErrorPage />,
        children: routes
      },
      {
        path: "/mobile",
        element: <DesktopLayout />,
        errorElement: <ErrorPage />,
        children: routes
      }
    ]
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<React.StrictMode>
  <RouterProvider router={router} />
  <Toaster />
</React.StrictMode>,);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
