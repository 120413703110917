/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { primaryButtonClass, tokens } from "pages/Mobile/Earn";
import AssetSelector from "components/SelectBonusAsset/AssetSelector";
import { baseUrl, BombApiContext } from "context/BombApiContext";
import { Web3BombappContext } from "context/Web3Bombapp";
import TransactionModal from "pages/Mobile/Positions/[token]/Components/TransactionModal";
import { BeefyContext } from "context/BeefyContext";
import useRefresh from "../../hooks/useRefresh";

const EmailRequired = ({ userInfo }: {  userInfo: any; }) => {
  const { setUserInfo } = React.useContext(BombApiContext);
  const [error, setError] = React.useState(null);

  return (
    <div>
      <p className="mb-2">First please provide your email:</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          // @ts-ignore
          const formData = new FormData(e.target);
          const formProps = Object.fromEntries(formData);
          (async () => {
            const newUserInfo = await fetch(`${baseUrl}/user`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  address: userInfo.wallet,
                  email: formProps.email,
                }),
              }).then((response) => response.json()).then((data) => {
              return data;
            });
            if (newUserInfo.error) {
              setError(newUserInfo.error);
            } else {
              setUserInfo(newUserInfo.user);
            }
          })();
        }}
      >
        {error && <p className="text-red-500 mb-2">{error}</p>}
        <input 
          type="email"
          name="email"
          className="text-black text-center border-2 border-gray-400 rounded-lg p-2 w-full"
          required
        />
        <div className="mt-2 flex justify-center">
          <button className={primaryButtonClass} type="submit">Continue</button>
        </div>
      </form>
    </div>
  );
};

const ConfirmAsset = ({ userInfo }: {  userInfo: any; }) => {
  const {
    createFreeStake
  } = React.useContext(Web3BombappContext);
  const { fastRefresh } = useRefresh();
  const [amount, setAmount] = React.useState(userInfo.depositBonusAmount ?? 0);
  const [amountInCoin, setAmountInCoin] = React.useState(null);
  const [selectedAsset, setSelectedAsset] = React.useState(null);
  const [transaction, setTransaction] = useState(null);
  const { fetchPrice } = React.useContext(BeefyContext);

  useEffect(() => {
    if (!fetchPrice) {
      return;
    }

    if (selectedAsset) {
      let foundToken;
      for (const token of Object.values(tokens)) {
        if (token.address.toLowerCase() === selectedAsset.assetContract.toLowerCase()) {
          foundToken = token;
          break;
        }
      }

      if (foundToken) {
        setAmountInCoin(amount / fetchPrice({ id: foundToken.beefySymbol }));
      }
    }
  }, [amount, selectedAsset, fetchPrice, fastRefresh]);

  useEffect(() => {
    if (!userInfo || !userInfo.wallet) {
      return;
    }

    fetch(`${baseUrl}/stake_assets/prepare_bonus/${userInfo.wallet}`, {
      method: "GET",
    }).then((response) => response.json());
  }, [userInfo]);

  return (
    <div>
      <TransactionModal transaction={transaction} setTransaction={setTransaction} />
      <div className="relative pb-4">
        <label
          htmlFor="select-bonus-asset-amount"
          className="mt-5 text-center block mb-2 text-sm text-[#f7f4f1] font-bold"
        >Please confirm your choices below!</label>
        <input
          id="select-bonus-asset-amount"
          type="range"
          min="10"
          max="1000"
          value={amount}
          onChange={(e) => setAmount(parseInt(e.target.value))}
          className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer accent-[#007D8C]"
        />
        <div className="absolute left-0 bottom-0 text-xs text-white">$10</div>
        <div className="absolute right-0 bottom-0 text-xs text-white">$1000</div>
        {(amount && amount > 0) ? (
          <div className="absolute w-full text-center text-sm text-white">
            ${amount}{
              selectedAsset && amountInCoin &&
              (<>&nbsp;({amountInCoin.toFixed(4)} {selectedAsset.assetSymbol})</>)
            }
          </div>
        ) : (<></>)}
      </div>
      {amount > 0 && (
        <div>
          <div className="text-center mt-3 text-white text-sm font-bold">
            Awesome! Now confirm the cryptocurrency you&apos;d like your bonus staking position
            to be delivered in?
          </div>
          <div className="mt-3">
            <AssetSelector
              defaultAssetContract={userInfo.depositBonusAsset}
              onChange={(asset) => { setSelectedAsset(asset); }}
            />
          </div>
          {selectedAsset && (
            <>
              <div className="mt-5 text-center text-white text-sm font-bold">
                Check out our amazing rates for {selectedAsset.assetSymbol}&nbsp;
                <a
                  href="https://blum.finance/rates/"
                  className="text-[#3D7B95]"
                  target="_blank"
                  rel="noreferrer"
                >here</a>.
              </div>
              <div
                className="text-left bg-white border border-black mt-5
                              text-black text-sm p-3 font-medium"
              >
                <div>
                  Claim your free staking position of ${amount} {
                    selectedAsset && amountInCoin &&
                  (<>({amountInCoin.toFixed(4)} {selectedAsset.assetSymbol})</>)
                  } and start earning rewards instantly!
                </div>
                <div>
                  You will then have 90 days to  stake {
                    selectedAsset && amountInCoin &&
                  (<>{(amountInCoin * 10).toFixed(4)} {selectedAsset.assetSymbol}</>)
                  } (${amount * 10} at current value) 
                  in order to claim your bonus, withdrawable in 180 days.
                </div>
                

                <div className="mt-3">Subject to &nbsp;
                  <a
                    href="https://blum.finance/terms"
                    target="_blank"
                    className="text-[#007D8C]"
                    rel="noreferrer"
                  >
                    terms & conditions
                  </a>.
                </div>
              </div>

              <div className="mt-5 flex justify-center">
                <button
                  className={primaryButtonClass}
                  onClick={() => {
                    if (!selectedAsset) {
                      alert("Please select an asset to continue.");
                      return;
                    }

                    const tx = createFreeStake(
                      selectedAsset.stakeContract,
                      amountInCoin,
                    );
                    setTransaction(tx);
                  }}
                >
                  Confirm and start earning
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default function ClaimBonusModal(
  { open, setOpen, userInfo }: { open: boolean; setOpen: (open: boolean) => void; userInfo: any; }
) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { setOpen(false); }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-2 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-[#033246] w-full
                lg:w-1/2 shadow-xl transition-all"
              >
                <div className="mt-3 mb-4 mx-5 text-white">
                  <h1 className="text-xl font-bold mb-3 text-center">Confirm your free staking position</h1>
                  {userInfo.hasEmail ? <ConfirmAsset userInfo={userInfo} /> : <EmailRequired userInfo={userInfo} />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
