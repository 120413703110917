import React, { useEffect, useLayoutEffect } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "tailwind.config.js";

// @ts-ignore
const config = resolveConfig(tailwindConfig);
const screens = config.theme.screens;

// https://github.com/pmndrs/zustand/blob/833f57ed131e94f3ed48627d4cfbf09cb9c7df03/src/react.ts#L20-L23
const isSSR =
  typeof window === "undefined" || !window.navigator || /ServerSideRendering|^Deno\//.test(window.navigator.userAgent);

const isBrowser = !isSSR;

const useIsomorphicEffect = isBrowser ? useLayoutEffect : useEffect;

export function useBreakpoint(breakpoint: string, defaultValue: boolean = false) {
  const [match, setMatch] = React.useState(() => defaultValue);
  const matchRef = React.useRef(defaultValue);

  useIsomorphicEffect(() => {
    if (!(isBrowser && "matchMedia" in window)) return undefined;

    function track() {
      // @ts-ignore
      const value = (screens[breakpoint] as string) ?? "999999px";
      const query = window.matchMedia(`(min-width: ${value})`);
      matchRef.current = query.matches;
      if (matchRef.current != match) {
        setMatch(matchRef.current);
      }
    }

    track();
    window.addEventListener("resize", track);
    return () => window.removeEventListener("resize", track);
  });

  return match;
}
