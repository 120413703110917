import React, { useEffect } from "react";
import { Web3BombappContext } from "context/Web3Bombapp";
import { BombApiContext } from "context/BombApiContext";
import { TokenType } from "types/Token";

function Buy({ token }: { token: TokenType; }) {
  // const [depositMinimumAmount, setDepositMinimumAmount] = React.useState<any | null>();
  const [depositWalletAddress, setDepositWalletAddress] = React.useState<string | null>(null);
  const { walletAddress } = React.useContext(Web3BombappContext);
  const { getDepositAddress } = React.useContext(BombApiContext);

  const network = token.onrampNetworkSlug;

  useEffect(() => {
    setDepositWalletAddress("");
    if (network) {
      getDepositAddress(network, walletAddress).then((response: any) => {
        if (response.error) {
          alert(response.error);
          return;
        }
        setDepositWalletAddress(response.depositAddress.address);
      });
      // getDepositAsset(address).then((response: any) => {
      //   if (response.error) {
      //     alert(response.error);
      //     return;
      //   }
      //   setDepositMinimumAmount(response.depositAsset.minimum);
      // });
    }
  }, [network]);

  if (!depositWalletAddress) {
    return <div>Loading...</div>;
  }

  const apiKey = "pk_prod_0vrzMi6KLQovJuRmTHacqL5Di2XSIcdUUczgtayejWs0";

  return (
    <iframe
      className="mx-auto"
      src={"https://widget.onramper.com?color=075985&apiKey=" + apiKey +
        "&supportSwap=false&supportSell=false&defaultCrypto=" + token.onrampSymbol +
        "&onlyCryptos=" + token.onrampSymbol + "&" +
        "wallets=" + token.onrampNetwork + ":" + depositWalletAddress +
        "," + token.onrampSymbol + ":" + depositWalletAddress +
        "&isAddressEditable=false&darkMode=false"}
      title="Onramper widget"
      frameBorder="0"
      allow="accelerometer;
    autoplay; camera; gyroscope; payment"
      style={{
        border: "none",
        width: "100%",
        maxWidth: "482px",
        height: "650px",
      }}
    >
      Loading...
    </iframe>
  );
}

export default Buy;
