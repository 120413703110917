import TokenSymbol from "components/TokenSymbol";
import React from "react";
import { TokenType } from "../../../types/Token";
import { TokenWithBalanceType } from "pages/Home";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { classNames } from "pages/Mobile/Positions/[token]/Components/DurationSelector";

function MyPortfolio(
  {portfolioAssetsTotal, stakedTotalsPerAsset}:
    {
      portfolioAssetsTotal: number,
      walletAssets: TokenWithBalanceType[],
      stakedTotalsPerAsset: { [key: string]: {token: TokenType; amount: number; dollarValue: number}; }
    }
) {
  const portfolioAssets: { [key: string]:{ token: TokenType, amount: number, amountUsd: number }} = {};
  for (const stakedAsset of Object.values(stakedTotalsPerAsset)) {
    if (!portfolioAssets[stakedAsset.token.address]) {
      portfolioAssets[stakedAsset.token.address] = { token: stakedAsset.token, amount: 0, amountUsd: 0 };
    }
    portfolioAssets[stakedAsset.token.address].amount += stakedAsset.amount;
    portfolioAssets[stakedAsset.token.address].amountUsd += stakedAsset.dollarValue;
  }
  /*for (const walletAsset of walletAssets) {
    if (!portfolioAssets[walletAsset.token.address]) {
      portfolioAssets[walletAsset.token.address] = { token: walletAsset.token, amount: 0, amountUsd: 0 };
    }
    portfolioAssets[walletAsset.token.address].amount += walletAsset.walletBalance;
    portfolioAssets[walletAsset.token.address].amountUsd += walletAsset.walletBalanceUsd;
  }*/

  const data = {
    labels: Object.values(portfolioAssets).map((asset) => asset.token.shortName),
    datasets: [
      {
        label: "Value",
        data: Object.values(portfolioAssets).map((asset) => asset.amountUsd),
        backgroundColor: Object.values(portfolioAssets).map((asset) => asset.token.bgColor),
        borderWidth: 0,
      },
    ],
  };
  const doughnutOptions = {
    plugins: {
      legend: {
        display: false
      },
    },
    elements: {
      center: {
        text: "$" + portfolioAssetsTotal.toFixed(2),
        color: "#000", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 10, // Default is 20 (as a percentage)
        minFontSize: 8, // Default is 20 (in px), set to false and text will not wrap.
        lineHeight: 25 // Default is 25 (in px), used for when text wraps
      }
    }
  };

  return (
    <div>
      <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>My portfolio</h1>
      <div className={classNames(portfolioAssetsTotal > 0 ? "hidden lg:block" : "hidden", "w-2/3 mx-auto")}>
        {/* @ts-ignore */}
        <Doughnut data={data} options={doughnutOptions} />
      </div>
      {Object.values(portfolioAssets).map((asset, i) => (
        <div key={i} className="grid grid-cols-2 gap-4 mt-5 rounded py-1 px-2">
          <div className="flex items-center justify-center lg:items-center lg:justify-start">
            <div>
              <TokenSymbol address={asset.token.address} className="inline w-12 h-12" />
            </div>
            <div className="w-1/3 ml-4 text-left text-sm">
              <div className="text-sm font-semibold text-gray-500">{asset.token.longName}</div>
              <div className="font-semibold">{asset.token.shortName}</div>
              {/*<div className="text-sm text-gray-500">
                <Link
                  to={"/#/mobile/positions/" + asset.token.address + "/buy"}
                  className="hover:text-bomb-yellow"
                >Buy</Link>&nbsp;&nbsp;&nbsp;
                <Link
                  to={"/#/mobile/positions/" + asset.token.address + "/deposit"}
                  className="hover:text-bomb-yellow"
                >Deposit</Link>
              </div>*/}
            </div>
          </div>
          <div className="self-center lg:justify-self-end text-xs font-bold text-right">
            <div>
              {asset.amount.toFixed(4)}
            </div>
            <div className="text-emerald-500">
              ${asset.amountUsd.toFixed(2)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MyPortfolio;

ChartJS.register(ArcElement, Tooltip, Legend);
const TextInCenterPlugin = {
  id: "textInCenter",
  beforeDraw: function(chart: any) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      var ctx = chart.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || "Arial";
      var txt = centerConfig.text;
      var color = centerConfig.color || "#000";
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      // Start with a base font of 30px
      ctx.font = "12px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var words = txt.split(" ");
      var line = "";
      var lines = [];

      // Break words up into multiple lines if necessary
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + " ";
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + " ";
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  },
};
ChartJS.register(TextInCenterPlugin);