import React, { useEffect, useState } from "react";
import DurationSelector from "./Components/DurationSelector";
import TokenSymbol from "../../../../components/TokenSymbol";
import { useParams } from "react-router-dom";
import { Web3BombappContext } from "context/Web3Bombapp";
import useRefresh from "../../../../hooks/useRefresh";
import { tokens } from "pages/Mobile/Earn";
import { PositionType } from "../../../../../types/Position";
import { BeefyContext } from "context/BeefyContext";
import TransactionModal from "./Components/TransactionModal";

function Compound() {
  const { address, tokenId } = useParams();
  const { slowRefresh } = useRefresh();
  const { walletAddress, stakeInfo, claimAndCompound } = React.useContext(Web3BombappContext);
  const { fetchPrice } = React.useContext(BeefyContext);

  const [position, setPosition] = React.useState<PositionType | null>(null);
  const [duration, setDuration] = React.useState<any|null>();
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    const decimals = 18;
    stakeInfo(tokens[address].stakeAddress, tokenId).then((position: any) => {
      const amount = Number(position.amountStaked) / Math.pow(10, decimals);
      setPosition({
        token: tokens[address],
        tokenId: Number(tokenId),
        amount: amount,
        allowWithdrawEarly: Boolean(position.allowWithdrawEarly),
        assetTransferred: Boolean(position.assetTransferred),
        apr: Number(position.apr) / 100,
        created: Number(position.created),
        lockTime: Number(position.lockTime),
        dollarValue: fetchPrice ? fetchPrice({ id: tokens[address].beefySymbol }) * amount : null,
      });
    });
  }, [fetchPrice, slowRefresh]);

  if (!position) {
    return null;
  }

  return (
    <div className="App">
      <TransactionModal transaction={transaction} setTransaction={setTransaction} />
      <div className="relative max-w-3xl">
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (!duration) {
              alert("Please select a duration");
              return;
            }

            (async() => {
              const tx = claimAndCompound(tokens[address].stakeAddress, walletAddress, position.tokenId, 0);
              setTransaction(tx);
            })();
          }}
        >
          <h1 className={"text-blueGray-700 text-xl font-bold mb-5"}>Compound</h1>
          <div className="mt-5 flex items-center">
            <div className="w-1/3">
              <TokenSymbol address={address} className="w-12 h-12 inline" />
              &nbsp; <span className="text-lg font-semibold">BTC</span>
            </div>
            <div className="w-2/3">
              <div className="text-gray-500 text-sm font-semibold">ANNUALIZED INTEREST RATE</div>
              <div className="text-green-300 text-3xl font-bold">{duration ? duration[0] / 100 : ""}%</div>
            </div>
          </div>
          <div className="text-white text-xl font-bold text-center">
            <div>
              Amount
            </div>
            <div className="">{position.amount} {tokens[address].shortName}</div>
          </div>
          <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-500" />
          <div className="mt-8">
            <div className="text-gray-400 text-left ml-5 mb-1">Duration</div>
            <DurationSelector
              stakeContract={position.token.stakeAddress}
              onChange={(value) => { setDuration(value); }}
            />
          </div>
          <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-500" />
          <div className="mt-5">
            <button className="bg-emerald-500 py-3 w-full rounded-full text-xl font-bold text-white">
              Compound
            </button>
          </div>
        </form>
        <div className="mt-5">&nbsp;</div>
      </div>
    </div>
  );
}

export default Compound;
