import React, { useEffect, useState } from "react";
import { BombApiContext } from "context/BombApiContext";
import ConfirmTermsModal from "components/ConfirmTerms/ConfirmTermsModal";

function ConfirmTerms() {
  const [confirmTermsOpen, setConfirmTermsOpen] = useState(false);
  const { userInfo, confirmTerms } = React.useContext(BombApiContext);

  useEffect(() => {
    if (userInfo && userInfo.confirmedTerms === null) {
      setConfirmTermsOpen(true);
    }
  }, [userInfo]);

  if (!userInfo || !userInfo.address) {
    return null;
  }

  if (userInfo.confirmedTerms === null) {
    return (
      <ConfirmTermsModal
        open={confirmTermsOpen}
        setOpen={setConfirmTermsOpen}
        onConfirmTerms={() => {
          (async () => {
            try {
              const result = await confirmTerms(userInfo.address);
              if (result.error) {
                throw new Error(result.error);
              }
              setConfirmTermsOpen(false);
            } catch (e) {
              console.error(e);
              alert("Error confirming terms");
            }
          })();
        }}
      />
    );
  }

  return null;
}

export default ConfirmTerms;
