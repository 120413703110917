import React, { MouseEventHandler } from "react";
import logo from "assets/images/blumfinance_logo.png";
import AuthLayout from "components/AuthLayout";

function SwitchChain(
  {
    onClick,
    onDisconnect
  } :
  {
    onClick: MouseEventHandler<HTMLButtonElement>,
    onDisconnect: MouseEventHandler<HTMLButtonElement>
  }
) {

  return (
    <AuthLayout>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative mb-8">
              <img src={logo} alt="Logo" className="w-full mx-auto w-11/12" />
            </div>
            <div
              className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200
            border-0"
            >
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    You are currently connected to a non-supported network.
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6
                     py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear
                     transition-all duration-150"
                    type="button"
                    onClick={onClick}
                  >
                    SWITCH TO BOMB CHAIN
                  </button>
                </div>
                <div className="flex-auto pt-0">
                  <div className="text-blueGray-400 text-center font-bold">
                    <small>
                      In order to interact with the Blum Finance Web3 app,
                      you need to connect your wallet to BOMB Chain.
                    </small>
                  </div>
                </div>
              </div>
              <hr className="mb-2 border-b-1 border-blueGray-300" />
              <div className="flex-auto pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or <span
                    className="cursor-pointer underline" onClick={onDisconnect}
                  >disconnect</span> to try with a different wallet.</small>
                </div>
              </div>
            </div>
            {/*<div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default SwitchChain;
